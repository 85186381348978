import "./preview.css";
import React, {
  lazy,
  Suspense,
  useState,
  useEffect,
  memo,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import CardCover from "./components/cover";
import OpeningHours from "./components/hours";
import IconsSlider from "./components/icons";
import Gallery from "./components/gallery";
import Reviews from "./components/reviews";
import Skeleton from "@mui/material/Skeleton";
import Meeting from "./components/meeting";
import Api from "../../api/api";
const Floating = lazy(() => import("./components/floating"));
const CardLogo = lazy(() => import("./components/logo"));
const MemoizedCardLogo = memo(CardLogo);

const CardPreview = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    bg_color,
    text_color,
    logo,
    cover_photo,
    bg_image,
    cover_color,
    likes,
    title,
    slogan,
    number,
    hours_bg,
    floating_bg,
    floating_icon,
    reviews_bg,
    hours,
    reviews,
    icons,
    settings,
    language,
    uid,
    views,
  } = useSelector((state) => state.card);

  const state = useSelector((state) => state.card);
  const [isBooking, setIsBooking] = useState(null);
  useEffect(() => {
    fetchBooking();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [state]);
  const fetchBooking = useCallback(async () => {
    const res = await Api.getBookingById(state.id);
    const bookingdata = res.data.data;
    if (bookingdata && res.status === 200) {
      const booking = bookingdata.booking;
      if (booking && booking.length > 0) {
        setIsBooking({
          cid: bookingdata.cid,
          name: bookingdata.name,
          email: bookingdata.email,
          phone: bookingdata.phone,
          booking: booking,
        });
      } else {
        setIsBooking(null);
      }
    }
  }, [state]);

  return (
    <>
      <div
        className={`card_preview ${
          window.location.pathname.includes("/card/") && "card_preview_width"
        }`}
        style={{
          background:
            bg_image && settings.bg_image ? `url(${bg_image})` : bg_color,
          backgroundPosition: bg_image && settings.bg_image && "initial",
          backgroundSize: bg_image && settings.bg_image && "cover",
          backgroundRepeat: bg_image && settings.bg_image && "no-repeat",
          color: text_color,
        }}
      >
        {" "}
        <CardCover
          likes={likes}
          uid={uid}
          coverPhoto={cover_photo}
          coverColor={cover_color}
          showCover={settings.cover_photo}
          showLikeBtn={settings.like_button}
          views={views}
        />
        <div
          className="card_preview_move"
          style={{ transform: `translateY(${settings.logo ? "-55px" : "0"})` }}
        >
          {isLoading ? (
            <div className="flex justify-center mx-auto h-[120px]]">
              <Skeleton variant="circular" height={120} width={120} />
            </div>
          ) : (
            <div>
              {settings.logo && (
                <Suspense
                  fallback={
                    <div className="flex justify-center mx-auto h-[120px]]">
                      <Skeleton variant="circular" height={120} width={120} />
                    </div>
                  }
                >
                  <MemoizedCardLogo
                    title={title}
                    logoURL={logo}
                    bg={bg_color}
                    color={text_color}
                    language={language}
                  />
                </Suspense>
              )}
            </div>
          )}
          <div className="h-7 my-2.5 flex items-center justify-center">
            {settings.title && (
              <div className="h-[25px] text-center">
                <span className=" text-lg font-bold">{title}</span>
              </div>
            )}
          </div>
          {isLoading ? (
            <div className="flex flex-col justify-center">
              <Skeleton variant="rounded" height={21} />
            </div>
          ) : (
            <div className="h-[21px] flex items-center justify-center">
              {settings.slogan && <div className="text-center">{slogan}</div>}
            </div>
          )}
          {isLoading ? (
            <div className="flex flex-col mt-4 justify-center">
              <Skeleton variant="rounded" height={75} />
            </div>
          ) : (
            <div>
              {settings.icons && (
                <IconsSlider language={language} title={title} icons={icons} />
              )}
            </div>
          )}
          {isLoading ? (
            <div className="flex flex-col h-24 justify-center">
              <div className="flex flex-col my-2.5 justify-center">
                <Skeleton variant="rounded" height={27} />
              </div>
              <Skeleton variant="rounded" height={49} />
            </div>
          ) : (
            <div>
              {settings.hours && (
                <OpeningHours
                  bg={hours_bg}
                  color={text_color}
                  hours={hours}
                  language={language}
                />
              )}
            </div>
          )}

          {settings.gallery && (
            <Gallery bg={bg_color} color={text_color} language={language} />
          )}
          {settings.reviews && (
            <Reviews
              bg={bg_color}
              color={text_color}
              logo={logo}
              title={title}
              language={language}
              reviews_bg={reviews_bg}
              reviews={reviews}
            />
          )}
          {settings.appointment && isBooking && (
            <Meeting
              booking={isBooking}
              bg={bg_color}
              color={text_color}
              logo={logo}
              title={title}
              language={language}
              reviews_bg={reviews_bg}
              reviews={reviews}
            />
          )}
        </div>
        <div className="px-3.5 pb-2">
          <Suspense fallback={<div>Loading...</div>}>
            <Floating
              language={language}
              title={title}
              number={number}
              bg={bg_color}
              color={text_color}
              icon={floating_icon}
              fbg={floating_bg}
              isShow={settings.floating}
              slogan={slogan}
              logo={logo}
            />
          </Suspense>
        </div>
        {isLoading ? (
          <div className="flex flex-col h-4 justify-center">
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          </div>
        ) : (
          <div className="copyright text-center text-xs mb-2.5">
            Copyright © {new Date().getFullYear()} Wazcard. All rights reserved.
          </div>
        )}
      </div>
    </>
  );
};

export default CardPreview;

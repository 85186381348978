import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "../../../common/Button";
import CustomModal from "../../../layout/modal/modal";
import DesignCard from "../../../layout/designCard/designCard";
import {
  deletePicture,
  designCard,
  editPicture,
  sortGallery,
} from "../../../store/slices/cardSlice";
import Api from "../../../api/api";
import { OVERRIDE } from "../../../utils/constants";
import { editSettings } from "../../../store/slices/cardSlice";

const Gallery = ({ toggle, setToggle }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.card);
  const { galleries: gallery, id } = useSelector((state) => state.card);
  const language = useSelector((state) => state.language);
  const [showModal, setShowModal] = useState(false);

  const settingsHandle = async (key) => {
    dispatch(
      editSettings({
        ...state,
        settings: { ...state.settings, [key]: !state.settings[key] },
      })
    );

    await Api.updateSettings({
      ...state.settings,
      [key]: !state.settings[key],
    });
  };

  return (
    <DesignCard
      title={language.gallery}
      toggle={toggle}
      onShow={() => setToggle(4, !toggle)}
      language={language.gallery}
      value={state.settings.gallery}
      settingsHandle={() => settingsHandle("gallery")}
    >
      {gallery.length < 10 && (
        <Button onClick={() => setShowModal(true)}>
          {language.addPicture}
        </Button>
      )}
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-2">
        {gallery.length > 0 &&
          gallery.map(({ id, image }, index) => (
            <Picture
              key={index}
              language={language}
              id={id}
              image={image}
              index={index}
              count={gallery.length}
            />
          ))}
      </div>
      {showModal && (
        <AddPicture
          language={language}
          cid={id}
          onHide={() => setShowModal(false)}
        />
      )}
    </DesignCard>
  );
};

const Picture = ({ id, image, index, count, language }) => {
  const dispatch = useDispatch();
  const [viewPicture, setViewPicture] = useState(false);

  const imageHandler = async (e) => {
    const { files } = e.target;
    if (!files[0]) return;
    const reader = new FileReader();
    reader.onload = async (event) => {
      dispatch(editPicture({ id, image: event.target.result }));

      const formData = {
        id: id,
        image: event.target.result,
      };
      await Api.editGallery(formData);
    };
    reader.readAsDataURL(files[0]);
  };

  const deletePic = async () => {
    dispatch(deletePicture(id));
    await Api.deleteGallery(id);
  };

  return (
    <div
      className="gallery_wrapper border border-solid border-gray-300 rounded-lg p-2"
      key={id}
    >
      <div className="gw1">
        <img
          src={image}
          alt="Gallery Picture_"
          loading="lazy"
          decoding="async"
        />
        <div className="galley_actions">
          <span onClick={() => setViewPicture(true)}>
            <i className="bi bi-eye"></i>
          </span>
          <label htmlFor="gallery_image">
            <span>
              <i className="bi bi-pencil-square"></i>
            </span>
            <input
              type="file"
              id="gallery_image"
              accept="image/*"
              onChange={imageHandler}
            />
          </label>
          <span onClick={deletePic}>
            <i className="bi bi-trash3"></i>
          </span>
        </div>
      </div>
      <div className="gw2">
        <span>{language.sortAt}</span>
        <select
          value={index + 1}
          onChange={(e) =>
            dispatch(
              sortGallery({ from: index, to: parseInt(e.target.value) - 1 })
            )
          }
        >
          {Array(count)
            .fill()
            .map((_, i) => (
              <option disabled={i === index} value={i + 1}>
                {i + 1}
              </option>
            ))}
        </select>
      </div>
      {viewPicture && (
        <ViewPicture
          language={language}
          image={image}
          onHide={() => setViewPicture(false)}
        />
      )}
    </div>
  );
};

const AddPicture = ({ onHide, cid, language }) => {
  const dispatch = useDispatch();
  const { title } = useParams();
  const [image, setImage] = useState("");
  const [isDisable, setIsDisable] = useState(false);

  const imageHandler = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadHandle = async () => {
    if (image) {
      setIsDisable(true);
      // dispatch(addPicture({ id: Date.now(), image }))

      const formData = {
        cid: cid,
        image: image,
      };
      const res = await Api.addGallery(formData);
      if (res.status === 201) {
        fetchCardData();
        setIsDisable(false);
        onHide();
      } else {
        setIsDisable(false);
      }
    }
  };

  const fetchCardData = useCallback(async () => {
    const res = await Api.getCard(title);
    if (res.status === 200) {
      dispatch(designCard(res.data.data));
      onHide();
    }
  }, []);

  return (
    <CustomModal rtl={language.rtl} title={language.addPicture} onHide={onHide}>
      {image && (
        <img
          src={image}
          style={{ width: "100%", objectFit: "cover", aspectRatio: 4 / 3 }}
          alt="add gallery"
        />
      )}
      <input
        type="file"
        onChange={(e) => imageHandler(e.target.files[0])}
        style={{ marginBottom: 6 }}
        accept="image/*"
      />
      <Button disabled={isDisable} width="100%" onClick={uploadHandle}>
        {!isDisable ? (
          language.upload
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </CustomModal>
  );
};

const ViewPicture = ({ onHide, image, language }) => {
  return (
    <CustomModal title={language.gallery} isBgShow={false} onHide={onHide}>
      {image && (
        <img
          src={image}
          style={{ width: "100%", objectFit: "cover" }}
          alt="View gallery"
        />
      )}
    </CustomModal>
  );
};

export default Gallery;

import "./Usermenu.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../common/Button";
import Cookies from "js-cookie";
import { JWT_STORAGE_NAME, LOCAL_STORAGE_NAME } from "../../utils/constants";
import { useSelector } from "react-redux";

const Usermenu = ({
  avatar,
  name,
  email,
  showUserMenuRef,
  setShowUserMenu,
}) => {
  const language = useSelector((state) => state.language);

  const logoutHandler = () => {
    Cookies.remove(JWT_STORAGE_NAME);
    localStorage.removeItem(JWT_STORAGE_NAME);
    localStorage.removeItem(LOCAL_STORAGE_NAME);
    window.location.href = "/";
  };

  useEffect(() => {
    let handler = (e) => {
      if (!showUserMenuRef.current.contains(e.target)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => document.removeEventListener("mousedown", handler);
  }, []);
  return (
    <div ref={showUserMenuRef} className="usermenu">
      <div className="usermenu_head">
        <img src={avatar} alt="" />
        <div>
          <p>{name}</p>
          <span>{email}</span>
        </div>
      </div>
      <div className="usermenu_body">
        {/* <Button width={"100%"}>Purchase a subscription</Button> */}
        <div className="username_links">
          <Link to="/dashboard">{language.myDigitalCard}</Link>
          <Link to="/account">{language.accSettings}</Link>
          <a href="#" onClick={logoutHandler}>
            <i className="bi bi-box-arrow-right"></i> {language.logout}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Usermenu;

import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { parsePhoneNumber } from "libphonenumber-js";
import Button from "../../../common/Button";
import DesignCard from "../../../layout/designCard/designCard";
import PhoneInput from "react-phone-number-input";
import { Datepicker, setOptions } from "@mobiscroll/react";
import Api from "../../../api/api";
import { Notifications } from "../../../helper/notifications";
import { updateBooking } from "../../../store/slices/cardSlice";
import { editSettings } from "../../../store/slices/cardSlice";

setOptions({
  theme: "ios",
});
const Appointment = ({ toggle, setToggle }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.card);
  const language = useSelector((state) => state.language);

  const [data, setData] = useState([]);

  const inputHandle = async (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };
  useEffect(() => {
    setData(state.booking);
  }, [state]);
  const editNumberHandle = async (e) => {
    const parsedPhoneNumber = parsePhoneNumber(e.nativeEvent.target.value);
    if (!parsedPhoneNumber.isValid()) return alert("Invalid number");
  };
  const submitHandle = async (e) => {
    console.log(transformedDatesTimes);
    e.preventDefault();
    const res = await Api.addBooking({
      ...data,
      cid: state.id,
      booking: transformedDatesTimes,
    });
    if (res.status === 200) {
      Notifications("success", res.data.message);
      dispatch(updateBooking(data));
    } else {
      Notifications("error", "Error");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const [selectedDatesTimes, setSelectedDatesTimes] = useState({});
  const [selectedDates, setSelectedDates] = useState([]);

  const timeSlots = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];

  const formatDate = (date) => {
    const d = new Date(date),
      year = d.getFullYear(),
      month = String(d.getMonth() + 1).padStart(2, "0"), // Months are zero-based
      day = String(d.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (event) => {
    const newDatesArray = event.value;
    setSelectedDates(newDatesArray);
    setData({
      ...data,
      ["booking"]: newDatesArray,
    });
  };
  useEffect(() => {
    const formattedDates = selectedDates.map((date) => formatDate(date));
    setSelectedDatesTimes((prev) => {
      const newSelectedDatesTimes = { ...prev };
      formattedDates.forEach((date) => {
        if (!newSelectedDatesTimes[date]) {
          newSelectedDatesTimes[date] = [];
        }
      });
      Object.keys(newSelectedDatesTimes).forEach((date) => {
        if (!formattedDates.includes(date)) {
          delete newSelectedDatesTimes[date];
        }
      });
      return newSelectedDatesTimes;
    });
  }, [selectedDates]);

  const handleTimeChange = (dateStr, time) => {
    setSelectedDatesTimes((prev) => ({
      ...prev,
      [dateStr]: prev[dateStr].includes(time)
        ? prev[dateStr].filter((t) => t !== time)
        : [...prev[dateStr], time],
    }));
  };

  const transformSelectedDatesTimes = () => {
    return Object.entries(selectedDatesTimes).flatMap(([date, times]) =>
      times.map((time) => new Date(`${date}T${time}Z`).toISOString())
    );
  };

  const transformedDatesTimes = transformSelectedDatesTimes();
  const settingsHandle = async (key) => {
    dispatch(
      editSettings({
        ...state,
        settings: { ...state.settings, [key]: !state.settings[key] },
      })
    );

    await Api.updateSettings({
      ...state.settings,
      [key]: !state.settings[key],
    });
  };
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  return (
    <DesignCard
      title={language.appointment}
      toggle={toggle}
      onShow={() => setToggle(8, !toggle)}
      language={language.appointment}
      value={state.settings.appointment}
      settingsHandle={() => settingsHandle("appointment")}
    >
      <form onSubmit={submitHandle}>
        <div className="hidden md:flex md:flex-col c_input_wrapper">
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-3">
            <div className="my-3">
              <label
                for="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                {language.name}
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={data.name}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder=""
                required
              />
            </div>
            <div className="my-3">
              <label
                for="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                {language.email}
              </label>
              <input
                type="text"
                name="email"
                id="email"
                value={data.email}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder=""
                required
              />
            </div>
          </div>

          <Number
            value={data.phone}
            inputHandle={inputHandle}
            editNumberHandle={editNumberHandle}
            placeholder="Business number"
          />
          <div>
            <label
              htmlFor="date"
              className="block mb-2 mt-3 text-sm font-medium text-gray-900"
            >
              {language.selectDates}
            </label>
            <div>
              <Datepicker
                controls={["calendar"]}
                selectMultiple={true}
                inputComponent="input"
                inputProps={{
                  placeholder: "Please Select...",
                }}
                value={data.booking}
                onChange={handleDateChange}
                min={new Date()}
              />
              <div className="grid grid-cols-1 2xl:grid-cols-2 gap-5">
                {Object.entries(selectedDatesTimes).map(([date, times]) => (
                  <div key={date}>
                    <h4 className="font-bold mt-2">{date}</h4>
                    <div className="grid grid-cols-4 gap-x-3">
                      {timeSlots.map((time, timeIndex) => (
                        <label
                          key={timeIndex}
                          style={{ margin: "5px" }}
                          className="flex gap-2 "
                        >
                          <div>
                            <input
                              className="flex w-5 h-5"
                              type="checkbox"
                              checked={times.includes(time)}
                              onChange={() => handleTimeChange(date, time)}
                            />
                          </div>
                          {time}
                        </label>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Button type="submit" className="mt-5">
            {language.addAppointment}
          </Button>
        </div>
        <div className="md:hidden c_input_wrapper">
          {currentStep === 1 && (
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-3">
              <div className="mt-3">
                <label
                  for="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  {language.name}
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={data.name}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder=""
                  required
                />
              </div>
              <div className="mt-3">
                <label
                  for="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  {language.email}
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={data.email}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder=""
                  required
                />
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <Number
              value={data.phone}
              inputHandle={inputHandle}
              editNumberHandle={editNumberHandle}
              placeholder="Business number"
            />
          )}
          {currentStep === 3 && (
            <div>
              <label
                htmlFor="date"
                className="block mb-2 mt-3 text-sm font-medium text-gray-900"
              >
                {language.selectDates}
              </label>
              <div>
                <Datepicker
                  controls={["calendar"]}
                  selectMultiple={true}
                  inputComponent="input"
                  inputProps={{
                    placeholder: "Please Select...",
                  }}
                  value={data.booking}
                  onChange={handleDateChange}
                  min={new Date()}
                />
                <div className="grid grid-cols-1 2xl:grid-cols-2 gap-5">
                  {Object.entries(selectedDatesTimes).map(([date, times]) => (
                    <div key={date}>
                      <h4 className="font-bold mt-2">{date}</h4>
                      <div className="grid grid-cols-4 gap-x-3">
                        {timeSlots.map((time, timeIndex) => (
                          <label
                            key={timeIndex}
                            style={{ margin: "5px" }}
                            className="flex gap-2 "
                          >
                            <div>
                              <input
                                className="flex w-5 h-5"
                                type="checkbox"
                                checked={times.includes(time)}
                                onChange={() => handleTimeChange(date, time)}
                              />
                            </div>
                            {time}
                          </label>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col mt-5 gap-2">
            {currentStep > 1 && <Button onClick={prevStep}>Previous</Button>}
            {currentStep < 3 && <Button onClick={nextStep}>Next</Button>}
            {currentStep === 3 && (
              <Button type="submit">{language.addAppointment}</Button>
            )}
          </div>
        </div>
      </form>
    </DesignCard>
  );
};
const Number = memo(({ value, placeholder, inputHandle }) => {
  const { name } = useParams();
  const countryCode = useMemo(() => {
    if (name) {
      const parts = name.split(".");
      const code = parts[parts.length - 1];
      return code.toUpperCase();
    }
  }, [name]);
  return (
    <div
      className="design_wrapper floating_number_wrapper"
      style={{ display: "flex", alignItems: "center", gap: 12 }}
    >
      <div className="main_icon_inputs">
        <PhoneInput
          value={value}
          onChange={(e) => inputHandle("phone", e)}
          placeholder={placeholder}
          defaultCountry={countryCode}
          countryCallingCodeEditable={false}
          international
          required
        />
      </div>
    </div>
  );
});
export default Appointment;

import { useCallback, useLayoutEffect, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { designCard } from "../store/slices/cardSlice";
import Api from "../api/api";
import Panel from "../layout/Panel/Panel";
import CardBuilder from "../components/builder/builder";
import Loader from "../components/loader/loader";
import { useSelector } from "react-redux";

const EditCard = () => {
  const { title } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [og, setOG] = useState({ title: "", logo: "" });

  const fetchCardData = useCallback(async () => {
    const res = await Api.getCard(title);
    if (res.status === 200) {
      if (res.data.data.language.rtl) {
        document.querySelector("body").classList.add("preview_rtl");
      }
      setOG({ title: res.data.data.title, logo: res.data.data.logo });
      dispatch(designCard(res.data.data));
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    } else if (res.status === 404) {
      window.location.href = "/";
    } else {
      setIsLoading(false);
    }
  }, []);
  useLayoutEffect(() => {
    fetchCardData();
    return () => {
      document.querySelector("body").classList.remove("preview_rtl");
    };
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Helmet>
            <title>{og.title}</title>
            <meta name="description" content={`${og.title}`} />
            <link rel="icon" type="image/png" href={`${og.logo}`} />
            <link rel="apple-touch-icon" href={`${og.logo}`} />
          </Helmet>
          <Panel>
            <CardBuilder />
          </Panel>
        </>
      )}
    </div>
  );
};

export default EditCard;

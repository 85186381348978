import React from "react";

const VisitorIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 26 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.502"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.204 3.09106C22.703 3.09106 23.919 4.25907 23.919 5.70007C23.919 7.14207 22.703 8.31007 21.204 8.31007C19.704 8.31007 18.489 7.14207 18.489 5.70007C18.489 4.25907 19.704 3.09106 21.204 3.09106ZM21.204 9.02806C20.347 9.03106 19.508 9.25607 18.772 9.67707C18.911 9.79707 19.051 9.91406 19.182 10.0431C20.052 10.9021 20.698 11.9471 21.068 13.0921C21.194 13.4691 21.288 13.8551 21.347 14.2471H25.356C25.709 14.2471 25.995 13.9731 25.995 13.6341C25.992 11.0911 23.849 9.03106 21.204 9.02806ZM8.49699 5.70007C8.49699 7.14207 7.28099 8.31007 5.78199 8.31007C4.28199 8.31007 3.06699 7.14207 3.06699 5.70007C3.06699 4.25907 4.28199 3.09106 5.78199 3.09106C7.28099 3.09106 8.49699 4.25907 8.49699 5.70007ZM5.78199 9.02806C3.13699 9.03106 0.99299 11.0911 0.98999 13.6341C0.98999 13.9731 1.27599 14.2471 1.62899 14.2471H5.63799C5.69799 13.8551 5.79099 13.4691 5.91799 13.0921C6.28699 11.9471 6.93399 10.9021 7.80399 10.0431C7.93399 9.91406 8.07399 9.79707 8.21299 9.67707C7.47799 9.25607 6.63799 9.03106 5.78199 9.02806Z"
        fill="#F9913A"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.407 15.9702H8.07796C7.74596 15.9702 7.47696 15.6952 7.47696 15.3562C7.47496 14.7052 7.57296 14.0582 7.76796 13.4382C8.05896 12.4802 8.56796 11.6062 9.25196 10.8872C11.669 8.35321 15.637 8.30221 18.116 10.7722C19.325 11.9772 20.007 13.6302 20.008 15.3562C20.008 15.6952 19.739 15.9702 19.407 15.9702ZM13.743 7.98821C11.847 7.98821 10.31 6.41721 10.31 4.47921C10.31 2.54121 11.847 0.970215 13.743 0.970215C15.639 0.970215 17.176 2.54121 17.176 4.47921C17.174 6.41721 15.638 7.98621 13.743 7.98821Z"
        fill="#F9913A"
      ></path>
    </svg>
  );
};

export default VisitorIcon;

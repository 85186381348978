import "react-phone-number-input/style.css";
import { useState, memo, useEffect, useLayoutEffect, useCallback } from "react";
import Switch from "react-switch";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { parsePhoneNumber } from "libphonenumber-js";
import { Helmet } from "react-helmet-async";
import PhoneInput from "react-phone-number-input";
import ClipLoader from "react-spinners/ClipLoader";
import Panel from "../layout/Panel/Panel";
import image from "../assets/business.webp";
import Button from "../common/Button";
import CustomModal from "../layout/modal/modal";
import Api from "../api/api";
import Loader from "../components/loader/loader";
import { OVERRIDE } from "../utils/constants";
import { lookupCountryName } from "../utils/countryNames";
import VisitorIcon from "../components/icon/visitor";
import CardsIcon from "../components/icon/cards";
import MoVisitor from "../components/icon/moVisitor";
import WeVisitor from "../components/icon/weVisitor";
import ReviewsIcon from "../components/icon/reviews";
import { Notifications } from "../helper/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faCopy } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";

const Dashboard = () => {
  const language = useSelector((state) => state.language);
  const [showModal, setShowModal] = useState(false);
  const [showTool, setShowTool] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const [languagesData, setLanguagesData] = useState([]);
  const [showLangModal, setShowLangModal] = useState(false);
  const [editLang, setEditLang] = useState({ id: 0, cid: 0 });
  const [weeklyViews, setWeeklyViews] = useState(0);
  const [monthlyViews, setMonthlyViews] = useState(0);
  const [reviews, setReviews] = useState(0);

  const getCards = useCallback(async () => {
    const res = await Api.getAllMyCards();
    if (res.status === 200) {
      setCards(res.data.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [setCards]);
  const getViews = useCallback(async () => {
    const res = await Api.getAllMyCardViews();
    if (res.status === 200) {
      setMonthlyViews(res.data.data.totalMonthlyViews);
      setWeeklyViews(res.data.data.totalWeeklyViews);
    }
  }, []);

  const getAllLanguages = useCallback(async () => {
    const res = await Api.getAllLanguages();
    if (res.status === 200) {
      setLanguagesData(res.data.data);
    }
  }, [setLanguagesData]);

  const deleteCard = useCallback(
    async (id) => {
      setIsLoading(true);
      const res = await Api.deleteCard(id);
      if (res.status === 200) {
        getCards();
        setIsLoading(false);
      }
      setIsLoading(false);
    },
    [setCards]
  );
  const getAllReviews = useCallback(async () => {
    const res = await Api.getPendingReviews();
    if (res.status === 200) {
      setReviews(res.data.data.length);
    }
  }, []);
  useLayoutEffect(() => {
    getCards();
    getViews();
    getAllLanguages();
    getAllReviews();
  }, []);

  const settingsHandle = async (data) => {
    if (data.bill == true) {
      if (data.status === "active") {
        const res = await Api.updateCardStatus({
          id: data.id,
          status: "inactive",
        });
        if (res.status === 200) {
          getCards();
          console.log(res);
          Notifications("success", res.data.message);
        }
      } else if (data.status === "inactive") {
        const res = await Api.updateCardStatus({
          id: data.id,
          status: "active",
        });
        if (res.status === 200) {
          getCards();
          Notifications("success", res.data.message);
        }
      }
    } else {
      Notifications("warning", "Please Subscribe");
    }
  };
  const toolsHandle = (id) => {
    if (showTool === id) {
      setShowTool("");
    } else {
      setShowTool(id);
    }
  };

  function handleCopy(url) {
    return () => {
      navigator.clipboard.writeText(url).then(
        () => {
          Notifications("success", "Link Copied!");
        },
        (err) => {
          Notifications("error", "Failed to copy URL");
        }
      );
    };
  }

  return (
    <>
      <Helmet>
        <title>{`Wazcard`}</title>
        <meta
          name="description"
          content={`Wazcard, the digital business cards`}
        />
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Panel>
            {cards && cards.length > 0 ? (
              <div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mb-12">
                  <div
                    className="c_card flex items-center"
                    style={{ height: "100%", overflowY: "scroll" }}
                  >
                    <WeVisitor />
                    <div className="flex flex-col">
                      <p className="text-xl text-gray-500 font-bold">
                        {weeklyViews}
                      </p>
                      <p className=" text-gray-400 ">{language.weVisitor}</p>
                    </div>
                  </div>
                  <div
                    className="c_card flex items-center"
                    style={{ height: "100%", overflowY: "scroll" }}
                  >
                    <MoVisitor />
                    <div className="flex flex-col">
                      <p className="text-xl text-gray-500 font-bold">
                        {monthlyViews}
                      </p>
                      <p className=" text-gray-400 ">{language.moVisitor}</p>
                    </div>
                  </div>
                  <div
                    className="c_card flex items-center"
                    style={{ height: "100%", overflowY: "scroll" }}
                  >
                    <CardsIcon />
                    <div className="flex flex-col">
                      <p className="text-xl text-gray-500 font-bold">
                        {cards.length}
                      </p>
                      <p className=" text-gray-400 ">{language.createdCard}</p>
                    </div>
                  </div>
                  <div
                    className="c_card flex items-center"
                    style={{ height: "100%", overflowY: "scroll" }}
                  >
                    <ReviewsIcon />
                    <div className="flex flex-col">
                      <p className="text-xl text-gray-500 font-bold">
                        {reviews}
                      </p>
                      <p className=" text-gray-400 ">Pending Reviews</p>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between mb-5">
                  <p className="flex text-lg md:text-2xl font-semibold items-center text-gray-500">
                    {" "}
                    {language.newCreatedCard}
                  </p>
                  <Button
                    onClick={() => setShowModal(true)}
                    style={{
                      marginLeft: !language.rtl && "auto",
                    }}
                  >
                    {language.createCard}
                  </Button>
                </div>

                <div className="flex flex-col gap-5">
                  {cards.map((item, index) => (
                    <div
                      className="c_card flex flex-col sm:flex-row justify-between gap-5"
                      style={{ height: "100%", overflowY: "scroll" }}
                    >
                      <div className="flex justify-between items-start gap-1">
                        <div className="flex gap-4">
                          <img
                            src={item.logo}
                            alt=""
                            width={72}
                            height={72}
                            className="w-[50px] h-[50px]  md:w-[72px] md:h-[72px] rounded-md"
                          />
                          <div className="flex flex-col justify-between">
                            <p className=" text-base text-gray-500 font-semibold">
                              {item.title}
                            </p>
                            <div className="flex gap-2 w-auto text-xs md:text-sm">
                              <Link to={`/preview/${item.URL_title}`}>
                                {`${window.location.host}/preview/${item.URL_title}`}
                              </Link>
                              <Tooltip title="Copy" arrow placement="top">
                                <div
                                  className="cursor-pointer rounded-md text-gray-400 hover:text-orange-400"
                                  onClick={handleCopy(
                                    `${window.location.host}/preview/${item.URL_title}`
                                  )}
                                >
                                  <FontAwesomeIcon icon={faCopy} size="lg" />
                                </div>
                              </Tooltip>
                            </div>

                            <div className="flex gap-1">
                              <VisitorIcon />
                              <p className="text-xs">Visitor ({item.views})</p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex sm:hidden gap-2 items-start justify-end  cursor-pointer hover:bg-orange-100 py-2 px-3 rounded-md text-gray-400 hover:text-orange-500"
                          onClick={() => toolsHandle(item.id)}
                        >
                          <FontAwesomeIcon
                            icon={faEllipsisVertical}
                            size="lg"
                          />
                        </div>
                      </div>
                      <div className="hidden sm:flex gap-2 items-center justify-end">
                        <Link to={`/card/${item.URL_title}`}>
                          <p className="py-2 px-10 bg-orange-100 rounded-lg text-orange-500 text-sm ">
                            {language.edit}
                          </p>
                        </Link>
                        <Switch
                          checked={item.status === "active" ? true : false}
                          onChange={() => settingsHandle(item)}
                          width={42}
                          height={20}
                          onColor="#2563EB"
                          handleDiameter={16}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        />
                        <Tooltip title="Language" arrow placement="top">
                          <span
                            onClick={() => {
                              setEditLang({
                                id: item.language.id,
                                cid: item.id,
                              });
                              setShowLangModal(true);
                            }}
                            className="hover:bg-orange-100 py-1 px-2 rounded-md flex items-center justify-between"
                          >
                            <i className="bi bi-globe2 cursor-pointer text-gray-300 text-base hover:text-orange-500"></i>
                          </span>
                        </Tooltip>
                        <Tooltip title="Delete" arrow placement="top">
                          <span
                            onClick={() => deleteCard(item.id)}
                            className="hover:bg-orange-100 py-1 px-2 rounded-md flex items-center justify-between"
                          >
                            <i className="bi bi-trash-fill cursor-pointer text-gray-300 text-base hover:text-orange-500"></i>
                          </span>
                        </Tooltip>
                      </div>
                      {showTool === item.id && (
                        <div className="sm:hidden flex gap-2 items-center justify-end">
                          <Link to={`/card/${item.URL_title}`}>
                            <p className="py-2 px-10 bg-orange-100 rounded-lg text-orange-500 text-sm ">
                              Edit
                            </p>
                          </Link>
                          <Switch
                            checked={item.status === "active" ? true : false}
                            onChange={() => settingsHandle(item)}
                            width={42}
                            height={20}
                            onColor="#2563EB"
                            handleDiameter={16}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          />
                          <Tooltip title="Language" arrow placement="top">
                            <span
                              onClick={() => {
                                setEditLang({
                                  id: item.language.id,
                                  cid: item.id,
                                });
                                setShowLangModal(true);
                              }}
                              className="hover:bg-orange-100 py-1 px-2 rounded-md flex items-center justify-between"
                            >
                              <i className="bi bi-globe2 cursor-pointer text-gray-300 text-base hover:text-orange-500"></i>
                            </span>
                          </Tooltip>
                          <Tooltip title="Delete" arrow placement="top">
                            <span
                              onClick={() => deleteCard(item.id)}
                              className="hover:bg-orange-100 py-1 px-2 rounded-md flex items-center justify-between"
                            >
                              <i className="bi bi-trash-fill cursor-pointer text-gray-300 text-base hover:text-orange-500"></i>
                            </span>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <img src={image} alt="" />
                <p>{language.pleaseCreateNewBusinessCard}</p>
                <Button onClick={() => setShowModal(true)}>
                  {language.createCard}
                </Button>
              </div>
            )}
            {showModal && (
              <CustomModal
                title="Create New Card"
                onHide={() => setShowModal(false)}
              >
                <AddForm languagesData={languagesData} />
              </CustomModal>
            )}
            {showLangModal && (
              <CustomModal
                title="Edit Card Language"
                onHide={() => setShowLangModal(false)}
              >
                <EditLanguage
                  languages={languagesData}
                  id={editLang.id}
                  cid={editLang.cid}
                />
              </CustomModal>
            )}
          </Panel>
        </div>
      )}
    </>
  );
};

const AddForm = memo(({ languagesData }) => {
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(false);
  const [data, setData] = useState({
    title: "",
    lang_id: "",
    number: "",
  });

  const submitHandle = async (e) => {
    e.preventDefault();
    const parsedPhoneNumber = parsePhoneNumber(data.number);
    if (!parsedPhoneNumber.isValid()) return alert("Invalid number");
    const countryCode = parsedPhoneNumber.country.toLowerCase();

    let title = data.title.trim().replaceAll(" ", "-");
    title = `${title}.${countryCode}`.toLowerCase();

    setIsDisabled(true);
    const res = await Api.createCard({
      title: data.title.trim(),
      URL_title: `${title}`,
      lang_id: data.lang_id,
      number: data.number,
      deviceName: getDeviceName(),
      country: lookupCountryName(countryCode),
    });
    if (res.status === 201) {
      history.push(`/card/${title}`);
      setIsDisabled(false);
    } else if (res.status === 409) {
      alert("Use unique title");
      setIsDisabled(false);
    } else {
      setIsDisabled(false);
    }
  };

  const getDeviceName = () => {
    const platform = navigator.platform?.toLowerCase();
    let deviceName = "";

    if (/win/.test(platform)) {
      deviceName = "Windows";
    } else if (/mac/.test(platform)) {
      deviceName = "Mac";
    } else if (/linux/.test(platform)) {
      deviceName = "Linux";
    } else if (/iphone|ipad|ipod/.test(platform)) {
      deviceName = "iOS";
    } else if (/arm|aarch/.test(platform)) {
      deviceName = "ARM-based";
    } else if (/android/.test(platform)) {
      deviceName = "Android";
    } else {
      deviceName = "";
    }

    return deviceName;
  };

  return (
    <form onSubmit={submitHandle}>
      <div className="c_input_wrapper">
        <input
          type="text"
          value={data.title}
          onChange={(e) =>
            setData((prev) => ({ ...prev, title: e.target.value }))
          }
          placeholder="Business Title"
          required
        />
      </div>
      <div className="c_input_wrapper">
        <select
          required
          name="language"
          value={data.lang_id}
          onChange={(e) =>
            setData((prev) => ({ ...prev, lang_id: e.target.value }))
          }
        >
          <option value="">-- Select Language --</option>
          {languagesData.map((item) => (
            <option value={item.id}>{item.lang_name}</option>
          ))}
        </select>
      </div>
      <div className="c_input_wrapper">
        <PhoneInput
          required
          value={data.number}
          onChange={(value) => setData((prev) => ({ ...prev, number: value }))}
          international
          placeholder="Number"
        />
      </div>
      <Button disabled={isDisabled} width="100%">
        {!isDisabled ? (
          "Create"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  );
});

const EditLanguage = memo(({ languages, id, cid }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [data, setData] = useState({
    id,
    cid,
  });

  const submitHandle = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    const res = await Api.updateCardLanguage(data);
    if (res.status === 200) {
      window.location.reload();
    }
  };

  useEffect(() => {
    setData({ id, cid });
  }, []);

  return (
    <form onSubmit={submitHandle}>
      <div className="c_input_wrapper">
        <select
          required
          name="language"
          value={data?.id}
          onChange={(e) => setData((prev) => ({ ...prev, id: e.target.value }))}
        >
          <option value="">-- Select Language --</option>
          {languages.map((item) => (
            <option value={item.id}>{item.lang_name}</option>
          ))}
        </select>
      </div>
      <Button disabled={isDisabled} width="100%">
        {!isDisabled ? (
          "Edit"
        ) : (
          <ClipLoader
            color="#FFFFFF"
            loading={true}
            cssOverride={OVERRIDE}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </Button>
    </form>
  );
});

export default Dashboard;

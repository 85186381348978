import { useState, memo, useCallback, useLayoutEffect, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactSwitch from "react-switch";
import { Helmet } from "react-helmet-async";
import Panel from "../layout/Panel/Panel";
import Api from "../api/api";
import Loader from "../components/loader/loader";

const Reviews = () => {
  const language = useSelector((state) => state.language);
  const [isLoading, setIsLoading] = useState(true);
  const [reviews, setReviews] = useState([]);

  const getAllReviews = useCallback(async () => {
    const res = await Api.getApprovedReviews();
    if (res.status === 200) {
      setReviews(res.data.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, []);

  const deleteReview = async (id) => {
    await Api.deleteReview(id);
    getAllReviews();
  };

  useLayoutEffect(() => {
    getAllReviews();
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Reviews | Wazcard`}</title>
        <meta
          name="description"
          content={`Wazcard, the digital business cards`}
        />
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Panel>
            <div className="c_card" style={{ height: "100%" }}>
              <div style={{ overflowX: "scroll" }}>
                <table className="cards_table">
                  <thead>
                    <tr>
                      <th>{language.title}</th>
                      <th style={{ width: "50%" }}>{language.reviews}</th>
                      <th>{language.rating}</th>
                      <th>{language.approved}</th>
                      <th style={{ width: 100 }}>{language.action}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reviews &&
                      reviews.map((item) => (
                        <Tr
                          id={item.id}
                          url_title={item.card.URL_title}
                          title={item.card?.title}
                          description={item.description}
                          rating={item.rating}
                          isApproved={item.isApproved}
                          deleteReview={deleteReview}
                          getReviews={getAllReviews}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Panel>
        </div>
      )}
    </>
  );
};

const Tr = memo(
  ({
    id,
    url_title,
    title,
    description,
    rating,
    isApproved,
    deleteReview,
    getReviews,
  }) => {
    const approveHandle = useCallback(async () => {
      try {
        await Api.editReviewStatus(id);
        getReviews();
      } catch (error) {
        console.error("Failed to update review status:", error);
      }
    }, [id, getReviews]);

    return (
      <tr key={id}>
        <td>{title}</td>
        <td>{description}</td>
        <td>{rating}</td>
        <td>
          <ReactSwitch
            checked={isApproved}
            onChange={approveHandle}
            width={42}
            height={20}
            onColor="#2563EB"
            handleDiameter={16}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          />
        </td>
        <td>
          <Link to={`/card/${url_title}`}>
            <i className="bi bi-eye-fill"></i>
          </Link>
          &nbsp;&nbsp;
          <span onClick={() => deleteReview(id)}>
            <i className="bi bi-trash-fill"></i>
          </span>
        </td>
      </tr>
    );
  }
);
export default Reviews;

import { memo, useState, useMemo, useEffect } from "react";
import {
  Datepicker,
  localeEn,
  localeAr,
  localeHe,
  setOptions,
} from "@mobiscroll/react";
import { useParams } from "react-router-dom";
import Booking from "../../../layout/modal/booking";
import PhoneInput from "react-phone-number-input";
import { useDispatch } from "react-redux";
import Api from "../../../api/api";
import { Notifications } from "../../../helper/notifications";
import bookingButton from "../../../assets/booking.webp";
import { useSelector } from "react-redux";
import { updateBooking } from "../../../store/slices/cardSlice";
setOptions({
  theme: "ios",
});
const Meeting = ({ color, language, booking }) => {
  const dispatch = useDispatch();
  const [addBooking, setAddBooking] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const state = useSelector((state) => state.card);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState([]);

  const myValid = useMemo(() => {
    const datesFromBooking = booking.booking || [];
    return datesFromBooking.map((dateRange) => ({
      start: dateRange,
      end: dateRange,
    }));
  }, [booking]);

  const isLocale = useMemo(() => {
    if (language.lang_code === "en") {
      return localeEn;
    } else if (language.lang_code === "ar") {
      return localeAr;
    } else if (language.lang_code === "he") {
      return localeHe;
    }
  }, [language.lang_code]);
  const handleChange = (event) => {
    const dateString = event.value;
    const dateObject = new Date(dateString);

    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const date = dateObject.getDate();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const seconds = dateObject.getSeconds();

    const pad = (n) => n.toString().padStart(2, "0");
    const localISOTime = `${year}-${pad(month)}-${pad(date)}T${pad(
      hours
    )}:${pad(minutes)}:${pad(seconds)}.000Z`;

    setSelectedDate(localISOTime);
    setData({
      ...data,
      ["booking"]: localISOTime,
    });
  };
  const createDate = () => {
    setIsSelect(true);
  };
  useEffect(() => {
    if (!addBooking) {
      setIsSelect(false);
      setSelectedDate(new Date());
    }
  }, [addBooking]);
  const inputHandle = async (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const numberHandle = async (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };
  const submitHandle = async (e) => {
    e.preventDefault();
    const res = await Api.sendBooking({
      ...data,
      cid: state.id,
      ["sendEmail"]: booking.email,
    });
    if (res.status === 200) {
      Notifications("success", res.data.message);
      const addBooking = {
        booking: res.data.data,
      };
      dispatch(updateBooking(addBooking));
      setAddBooking(false);
    } else {
      Notifications("error", "Error");
      setAddBooking(false);
    }
  };
  return (
    <div className="card_reviews flex items-center justify-center mt-5">
      <button
        className="relative rounded-lg"
        onClick={() => setAddBooking(true)}
      >
        <img
          src={bookingButton}
          alt="GIF Description"
          className=""
          width={200}
          height={200}
        />
        <p className="absolute top-5 right-10 text-sm">
          {language.bookMeeting}
        </p>
      </button>
      {addBooking && (
        <Booking
          rtl={language.rtl}
          isBgShow={true}
          color={color}
          title={language.addHome}
          onHide={() => setAddBooking(false)}
        >
          <form onSubmit={submitHandle}>
            <div className="p-5">
              {!isSelect && (
                <div className="flex flex-col gap-2 pt-3">
                  <Datepicker
                    controls={["calendar", "timegrid"]}
                    display="inline"
                    stepMinute={60}
                    valid={myValid}
                    touchUi={true}
                    locale={isLocale}
                    value={selectedDate}
                    onChange={handleChange}
                  />
                  <button className="w-full bg-white rounded-lg py-3">
                    <p
                      className="text-[#AD7D53] text-base"
                      onClick={() => createDate()}
                    >
                      {language.bookMeeting}
                    </p>
                  </button>
                </div>
              )}
              {isSelect && (
                <div className="flex flex-col gap-2 pt-3 text-base">
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-base font-medium text-white"
                    >
                      {language.name}
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={data.name}
                      onChange={inputHandle}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder=""
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-base font-medium text-white"
                    >
                      {language.email}
                    </label>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      value={data.email}
                      onChange={inputHandle}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder=""
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-base font-medium text-white"
                    >
                      {language.phoneNumber}
                    </label>
                    <Number
                      value={data.phone}
                      inputHandle={numberHandle}
                      // editNumberHandle={editNumberHandle}
                      placeholder="Business number"
                    />
                  </div>
                  <button className="w-full bg-white rounded-lg py-3 mt-3 ">
                    <p className="text-[#AD7D53] text-base" type="submit">
                      {language.createMeeting}
                    </p>
                  </button>
                </div>
              )}
            </div>
          </form>
        </Booking>
      )}
    </div>
  );
};
const Number = memo(({ value, placeholder, inputHandle }) => {
  const { name } = useParams();
  const countryCode = useMemo(() => {
    if (name) {
      const parts = name.split(".");
      const code = parts[parts.length - 1];
      return code.toUpperCase();
    }
  }, [name]);

  return (
    <div
      className="design_wrapper floating_number_wrapper bg-white text-base"
      style={{ display: "flex", alignItems: "center", gap: 12 }}
    >
      <div className="main_icon_inputs">
        <PhoneInput
          value={value}
          onChange={(e) => inputHandle("phone", e)}
          // onBlur={editNumberHandle}
          placeholder={placeholder}
          defaultCountry={countryCode}
          countryCallingCodeEditable={false}
          international
          required
        />
      </div>
    </div>
  );
});
export default memo(Meeting);

import "./Header.css";
import { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LANG_NAME, LOCAL_STORAGE_NAME } from "../../utils/constants";
import { langLoader } from "../../store/slices/langSlice";
import logo from "../../assets/Mask group.webp";
import Api from "../../api/api";
import Usermenu from "../usermenu/Usermenu";
import { Link } from "react-router-dom";
import { IoEarthSharp } from "react-icons/io5";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Header = ({ ltl, setLtl }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const row = !ltl ? "flex-row-reverse" : "flex-row";
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [languagesData, setLanguagesData] = useState([]);
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem(LANG_NAME) || "English"
  );
  const [nav, setNav] = useState(false);

  const openMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  const langHandle = async (e) => {
    const lang_code = e.target.value;
    setSelectedLang(lang_code);
    localStorage.setItem(LANG_NAME, lang_code);
    dispatch(langLoader(true));
    i18next.changeLanguage(lang_code);
  };

  const getAllLanguages = useCallback(async () => {
    const res = await Api.getAllLanguages();
    if (res.status === 200) {
      setLanguagesData(res.data.data);
    }
  }, []);

  useEffect(() => {
    getAllLanguages();
  }, []);

  return (
    <nav className="bg-white border-gray-200 ">
      <div
        className={
          "max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 " +
          row
        }
      >
        <a className="flex items-center">
          <img src={logo} className="h-8 mr-3" alt="Flowbite Logo" />
          <span className="self-center text-sm text-blue-500">
            Digital Idea
          </span>
        </a>
        <div
          className={
            "cursor-pointer group md:hidden " + (!ltl ? "mr-auto" : "ml-auto")
          }
        >
          <IoEarthSharp />{" "}
          {/* <div className="relative">
            <select
              value={selectedLang}
              onChange={langHandle} // Call langHandle when the select value changes
              className="rounded-md border border-gray-300 px-3 py-2 text-sm text-gray-600 focus:outline-none focus:border-blue-500"
            >
              {languagesData.map((lang) => (
                <option key={lang.lang_code} value={lang.lang_code}>
                  {lang.lang_name}
                </option>
              ))}
            </select>
          </div> */}
          <div className="relative" style={{ zIndex: 10 }}>
            <div className="absolute top-[1px] right-[-5px] hidden group-hover:block hover:block">
              <div className="py-3 ">
                <div className="w-4 h-4 right-3 absolute mt-1 bg-slate-100 rotate-45" />
              </div>
              <div className="bg-slate-100 px-3.5 pt-3.5 w-[200px]">
                <>
                  {languagesData.map((lang) => (
                    <div className="pb-3.5" key={lang.lang_code}>
                      <button
                        onClick={() => {
                          setLtl(!lang.rtl);
                          i18next.changeLanguage(lang.lang_code);
                        }}
                        className="text-sm text-gray-600 hover:text-blue-600"
                      >
                        {lang.lang_name}
                      </button>
                    </div>
                  ))}
                </>
              </div>
            </div>
          </div>
        </div>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={() => setNav(!nav)}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={"w-full md:block md:w-auto " + (nav ? "" : "hidden")}
          id="navbar-default"
        >
          <ul className="font-medium md:flex p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white ">
            <li>
              <p
                className={
                  "text-sm text-gray-900 md:hover:text-blue-700 pl-3 pr-4 md:p-0 ml-4"
                }
              >
                {t("aboutUs")}
              </p>
            </li>
            <li>
              <p className="text-sm text-gray-900 md:hover:text-blue-700 pl-3 pr-4 md:p-0 ml-4">
                {t("packages")}
              </p>
            </li>
            <li>
              <p className="text-sm text-gray-900 md:hover:text-blue-700 pl-3 pr-4 md:p-0 ml-4">
                {t("howItWorks")}
              </p>
            </li>
            <li className="hidden md:block">
              <div className="md:cursor-pointer group">
                <IoEarthSharp />
                <div className="relative" style={{ zIndex: 10 }}>
                  <div className="absolute top-[1px] right-[-5px] hidden group-hover:block hover:block">
                    <div className="py-3 ">
                      <div className="w-4 h-4 right-3 absolute mt-1 bg-slate-100 rotate-45" />
                    </div>
                    <div className="bg-slate-100 px-3.5 pt-3.5 w-[200px]">
                      <>
                        {languagesData.map((lang) => (
                          <div className="pb-3.5" key={lang.lang_code}>
                            <button
                              onClick={() => {
                                setLtl(!lang.rtl);
                                // set into local storage
                                localStorage.setItem(
                                  "wazcardLang",
                                  lang.lang_code
                                );
                                i18next.changeLanguage(lang.lang_code);
                              }}
                              className="text-sm text-gray-600 hover:text-blue-600"
                            >
                              {lang.lang_name}
                            </button>
                          </div>
                        ))}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="mt-5 md:mt-0">
              {!localStorage.getItem(LOCAL_STORAGE_NAME) ? (
                <>
                  <a
                    href="/login"
                    className="border border-blue-500 text-blue-500 hover:text-blue-500 rounded-md  px-6 py-2 text-sm"
                  >
                    {t("login")}
                  </a>
                  <a
                    href="/signup"
                    className=" bg-blue-500 border border-blue-500 ml-2 rounded-md text-white hover:text-white px-6 py-2 text-sm md:hidden"
                  >
                    {t("signup")}
                  </a>
                </>
              ) : (
                <Link
                  to="/dashboard"
                  className=" bg-blue-500 border border-blue-500 rounded-md text-white hover:text-white px-6 py-2 text-sm md:hidden"
                >
                  Dashboard
                </Link>
              )}
            </li>
            <li className="hidden md:block">
              {!localStorage.getItem(LOCAL_STORAGE_NAME) ? (
                <a
                  href="/signup"
                  className="bg-blue-500 rounded-md text-white hover:text-white px-6 py-2 text-sm"
                >
                  {t("signup")}
                </a>
              ) : (
                <Link
                  to="/dashboard"
                  className="bg-blue-500 rounded-md text-white hover:text-white px-6 py-2 text-sm"
                >
                  Dashboard
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;

export const LINKS = [
  {
    name: "My Digital Cards",
    href: "/dashboard",
    translation: "myDigitalCard",
  },
  {
    name: "Reviews",
    translation: "reviews",
    subItems: [
      {
        name: "Pending",
        translation: "pending",
        href: "/reviews/pending",
      },
      {
        name: "Approved",
        translation: "approved",
        href: "/reviews/approved",
      },
    ],
  },
  {
    name: "Account Settings",
    translation: "accSettings",
    href: "/account",
  },
];

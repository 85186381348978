import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReviewCard from "../../review/review";
import Button from "../../../common/Button";
import DesignCard from "../../../layout/designCard/designCard";
import AddReview from "../../review/add";
import { designCard } from "../../../store/slices/cardSlice";
import Api from "../../../api/api";
import { editSettings } from "../../../store/slices/cardSlice";

const Reviews = ({ toggle, setToggle }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.card);
  const language = useSelector((state) => state.language);
  const [showModal, setShowModal] = useState(false);

  const colorInputHandle = (e) => {
    const { name, value } = e.target;
    dispatch(designCard({ ...state, [name]: value }));
  };

  const submitColorHandle = async (e) => {
    const { name, value } = e.target;
    await Api.updateReviewColor({ id: state.id, [name]: value });
  };
  const settingsHandle = async (key) => {
    dispatch(
      editSettings({
        ...state,
        settings: { ...state.settings, [key]: !state.settings[key] },
      })
    );

    await Api.updateSettings({
      ...state.settings,
      [key]: !state.settings[key],
    });
  };

  return (
    <DesignCard
      title={language.reviews}
      toggle={toggle}
      onShow={() => setToggle(5, !toggle)}
      language={language.reviews}
      value={state.settings.reviews}
      settingsHandle={() => settingsHandle("reviews")}
    >
      <ColorInput
        type="color"
        className="px-5"
        label={language.backgroundColor}
        name="reviews_bg"
        value={state.reviews_bg}
        inputHandle={colorInputHandle}
        submitData={submitColorHandle}
      />
      <Button onClick={() => setShowModal(true)}>{language.addReviews}</Button>

      {state.reviews.length > 0 &&
        state.reviews
          .map((item) => (
            <ReviewCard
              id={item.id}
              name={item.name}
              rating={item.rating}
              description={item.description}
              createdAt={new Date(item.createdAt).toLocaleDateString()}
              showAction={true}
              logo={state.logo}
              title={state.title}
            />
          ))
          .reverse()}

      {showModal && (
        <AddReview
          language={language}
          isApproved={true}
          logo={state.logo}
          title={state.title}
          isBgShow={false}
          onHide={() => setShowModal(false)}
        />
      )}
    </DesignCard>
  );
};
const ColorInput = ({ type, label, name, value, inputHandle, submitData }) => {
  return (
    <div className="design_input_wrapper ">
      <label className="flex justify-between gap-1 font-semibold pb-2">
        {label}
      </label>
      <div className="flex px-3 py-1 border border-solid border-gray-300 rounded-lg text-center items-center justify-between">
        <div className="w-12 rounded-lg">
          <input
            type={type}
            name={name}
            value={value}
            onChange={inputHandle}
            onBlur={submitData}
            className="w-full rounded-lg border-none focus:ring-0"
          />
        </div>
        <label>{value}</label>
      </div>
    </div>
  );
};
export default Reviews;

import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import ClipLoader from "react-spinners/ClipLoader";
import CustomModal from "../../../layout/modal/modal";
import { OVERRIDE } from "../../../utils/constants";
import Skeleton from "@mui/material/Skeleton";
import LazyLoad from "react-lazyload";

const Gallery = ({ color, bg, language }) => {
  const { galleries: gallery } = useSelector((state) => state.card);
  const [imageActive, setImageActive] = useState(0);
  const [viewPicture, setViewPicture] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const responsive = {
    mobile: {
      breakpoint: { max: 4000, min: 0 },
      items: 4,
      slidesToSlide: 4,
    },
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="card_gallery h-[148px]">
      {isLoading ? (
        <div className="flex flex-col justify-center">
          <Skeleton variant="rounded" height={148} />
        </div>
      ) : (
        <>
          <div className="card_title w-full h-6">{language.gallery}</div>
          <Carousel
            responsive={responsive}
            arrows={false}
            showDots={true}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {gallery.length > 0 &&
              gallery.map((item, index) => (
                <LazyLoad height={200} offsetVertical={100} key={index}>
                  <img
                    key={index}
                    src={item.image}
                    width={80}
                    height={80}
                    onClick={() => [
                      setViewPicture(true),
                      setImageActive(index),
                    ]}
                    alt="gallery"
                    loading="lazy"
                    fetchpriority="low"
                  />
                </LazyLoad>
              ))}
          </Carousel>
          {viewPicture && (
            <ViewPicture
              language={language}
              bg={bg}
              color={color}
              gallery={gallery}
              imageActive={imageActive}
              onHide={() => setViewPicture(false)}
            />
          )}
        </>
      )}
    </div>
  );
};

const ViewPicture = ({ bg, onHide, imageActive, gallery, color, language }) => {
  const responsive = {
    mobile: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  const carouselRef = useRef(null);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.goToSlide(imageActive);
      setTimeout(() => {
        setShow(true);
      }, [800]);
    }
  }, []);
  return (
    <CustomModal
      rtl={language.rtl}
      title={language.gallery}
      bg={bg}
      color={color}
      isBgShow={true}
      onHide={onHide}
    >
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        arrows={true}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {gallery.length > 0 &&
          gallery.map((item, index) =>
            show ? (
              <img
                key={index}
                src={item.image}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  aspectRatio: 4 / 3,
                }}
                alt="gallery"
                loading="lazy"
              />
            ) : (
              <ClipLoader
                color={color}
                loading={true}
                cssOverride={OVERRIDE}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )
          )}
      </Carousel>
    </CustomModal>
  );
};

export default memo(Gallery);

import "./Panel.css";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import { useSelector } from "react-redux";

const Panel = ({ children }) => {
  const toggleSidebar = useSelector((state) => state.sidebar);
  return (
    <section>
      <Header />
      <div className="dashboard_cont max-w-[1200px] mx-auto px-4 pb-16">
        {children}
      </div>
    </section>
  );
};

export default Panel;

import React from "react";

const MoVisitor = () => {
  return (
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_367_24)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.495 6C51.127 6 62.989 17.862 62.989 32.495C62.989 47.127 51.127 58.989 36.495 58.989C21.862 58.989 10 47.127 10 32.495C10 17.862 21.862 6 36.495 6Z"
          fill="#FF6792"
        ></path>
      </g>
      <path
        d="M36.495 6C51.127 6 62.989 17.862 62.989 32.495C62.989 47.127 51.127 58.989 36.495 58.989C21.862 58.989 10 47.127 10 32.495C10 17.862 21.862 6 36.495 6Z"
        fill="url(#paint0_linear_367_24)"
      ></path>
      <path
        opacity="0.502"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.204 27.0911C45.703 27.0911 46.919 28.2591 46.919 29.7001C46.919 31.1421 45.703 32.3101 44.204 32.3101C42.704 32.3101 41.489 31.1421 41.489 29.7001C41.489 28.2591 42.704 27.0911 44.204 27.0911ZM44.204 33.0281C43.347 33.0311 42.508 33.2561 41.772 33.6771C41.911 33.7971 42.051 33.9141 42.182 34.0431C43.052 34.9021 43.698 35.9471 44.068 37.0921C44.194 37.4691 44.288 37.8551 44.347 38.2471H48.356C48.709 38.2471 48.995 37.9731 48.995 37.6341C48.992 35.0911 46.849 33.0311 44.204 33.0281ZM31.497 29.7001C31.497 31.1421 30.281 32.3101 28.782 32.3101C27.282 32.3101 26.067 31.1421 26.067 29.7001C26.067 28.2591 27.282 27.0911 28.782 27.0911C30.281 27.0911 31.497 28.2591 31.497 29.7001ZM28.782 33.0281C26.137 33.0311 23.993 35.0911 23.99 37.6341C23.99 37.9731 24.276 38.2471 24.629 38.2471H28.638C28.698 37.8551 28.791 37.4691 28.918 37.0921C29.287 35.9471 29.934 34.9021 30.804 34.0431C30.934 33.9141 31.074 33.7971 31.213 33.6771C30.478 33.2561 29.638 33.0311 28.782 33.0281Z"
        fill="white"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.407 39.9702H31.078C30.746 39.9702 30.477 39.6952 30.477 39.3562C30.475 38.7052 30.573 38.0582 30.768 37.4382C31.059 36.4802 31.568 35.6062 32.252 34.8872C34.669 32.3532 38.637 32.3022 41.116 34.7722C42.325 35.9772 43.007 37.6302 43.008 39.3562C43.008 39.6952 42.739 39.9702 42.407 39.9702ZM36.743 31.9882C34.847 31.9882 33.31 30.4172 33.31 28.4792C33.31 26.5412 34.847 24.9702 36.743 24.9702C38.639 24.9702 40.176 26.5412 40.176 28.4792C40.174 30.4172 38.638 31.9862 36.743 31.9882Z"
        fill="white"
      ></path>
      <defs>
        <filter
          id="filter0_d_367_24"
          x="0.202"
          y="0.202"
          width="72.585"
          height="72.585"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="4.899"></feGaussianBlur>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.992157 0 0 0 0 0.360784 0 0 0 0 0.541176 0 0 0 0.32 0"
          ></feColorMatrix>
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_367_24"
          ></feBlend>
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_367_24"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_367_24"
          x1="10"
          y1="58.989"
          x2="10"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD5C8A"></stop>
          <stop offset="1" stopColor="#FF84A7"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MoVisitor;

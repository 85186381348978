import { FaFacebookF, FaCcStripe, FaCcPaypal, FaCcVisa } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { AiOutlinePhone } from "react-icons/ai";
import logo from "../../assets/whiteLogo.webp";
import React from "react";
import { t } from "i18next";

function Footer({ ltl }) {
  return (
    <div className="bg-blue-600 ">
      <footer className="max-w-screen-xl m-auto pl-5  sm:p-6 bg-blue-600">
        <div className=" ">
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4 items-center">
            <div
              className={
                "mb-6 sm:mb-1 md:col-span-2 mt-3 md:mt-0" +
                (!ltl && " md:order-4")
              }
            >
              <div className="flex flex-row items-center mb-3">
                <img src={logo} className="w-8 h-8" />
                <h2 className=" text-sm font-semibold text-white uppercase ">
                  Digital Idea
                </h2>
              </div>
              <span className="text-xs font-light text-gray-200 ">
                {t("footerText")}
              </span>
            </div>

            <div className={"mb-3 sm:mb-1" + (!ltl && " md:order-3")}>
              <h2 className="mb-1 sm:mb-3 text-sm font-semibold uppercase text-white">
                {t("links")}{" "}
              </h2>
              <ul className="text-white text-xs mb-1 ">
                <li className="mb-2">
                  <a to="#about" className="hover:underline text-gray-200">
                    {t("aboutUs")}
                  </a>
                </li>
                <li className="mb-2">
                  <a to="#working" className="hover:underline text-gray-200">
                    {t("howItWorks")}
                  </a>
                </li>
                <li className="mb-2">
                  <a to="#packages" className="hover:underline text-gray-200">
                    {t("packages")}
                  </a>
                </li>
              </ul>
            </div>

            <div className={"mb-3 sm:mb-1" + (!ltl && " md:order-2")}>
              <h2 className="mb-1 sm:mb-3 text-sm font-semibold uppercase text-white">
                {t("legal")}
              </h2>
              <ul className="text-white text-xs mb-1 ">
                <li className="mb-2">
                  <a to="/about" className="hover:underline text-gray-200">
                    {t("termAndServices")}
                  </a>
                </li>
                <li className="mb-2">
                  <a to="/about" className="hover:underline text-gray-200">
                    {t("privacyPolicy")}
                  </a>
                </li>
                <li className="mb-2">
                  <a to="/about" className="hover:underline text-gray-200">
                    {t("cookiePolicy")}
                  </a>
                </li>
              </ul>
            </div>

            <div className={"mb-3 sm:mb-1" + (!ltl && " md:order-1")}>
              <h2 className="mb-1 sm:mb-3 text-sm font-semibold uppercase text-white">
                {t("contactUs")}
              </h2>
              <ul className="text-white text-xs mb-1 ">
                <li className="mb-2">
                  <span className="hover:underline text-gray-200 flex flex-row items-center">
                    <MdOutlineEmail className="mr-1" /> {t("email")}
                  </span>
                </li>
                <li className="mb-2">
                  <span className="hover:underline text-gray-200 flex flex-row items-center">
                    <AiOutlinePhone className="mr-1" /> {t("phone")}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
        <div
          className={
            "sm:flex sm:items-center sm:justify-between text-center " +
            (ltl ? "sm:flex-row" : "sm:flex-row-reverse")
          }
        >
          <span className="text-sm text-gray-200 text-center ">
            © {t("copyright")}{" "}
            <a href="https://wazcard.com/" className="hover:underline">
              wazcard.com{" "}
            </a>
            {t("allRightsReserved")}
          </span>
          <div className="flex flex-row mr-2">
            <FaCcVisa
              className="h-10 w-10 mr-3  text-white "
              alt="Facebook Logo"
            />

            <FaCcPaypal
              className="h-10 w-10 mr-3  text-white "
              alt="Facebook Logo"
            />

            <FaCcStripe
              className="h-10 w-10 mr-3  text-white "
              alt="Facebook Logo"
            />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;

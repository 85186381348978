import "./navbar.css";
import "./footer.css";
import "./pricing.css";
import "./banner.css";
import React, { useState } from "react";
import headingLine from "../../assets/homepage/headingLine.webp";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Header from "./Header";
import Banner from "../Banner/banner";
import Working from "../how_it_works/working";
import Collection from "../collection/collection";
import BusinessInfo from "../businessInfo/BusinessInfo";
import CustomerTestimonials from "../CustomerTestimonials/CustomerTestimonials";
import Footer from "../footer/Footer";
import i18next, { t } from "i18next";
import { useLayoutEffect } from "react";
import { useCallback } from "react";
import Api from "../../api/api";

function HomeComponent({ testimonials, hero, working, business }) {
  let [ltl, setLTL] = useState(true); // row = flase
  let [loaded, setLoaded] = useState(false);
  let [lang, setLang] = useState([]);
  let [languages, setLanguages] = useState({});
  const [images, setImages] = useState([]);

  const getAllLanguages = useCallback(async () => {
    const res = await Api.getAllLanguages();
    if (res.status === 200) {
      // console.log(res.data.data);

      let resources = {};
      res.data.data.forEach((lang) => {
        resources[lang.lang_code] = {
          translation: lang,
        };
      });
      setLang(res.data.data);
      setLanguages(resources);
      setLoaded(true);
    }
  }, []);

  useLayoutEffect(() => {
    if (loaded) {
      const langSt = localStorage.getItem("wazcardLang");
      const chossen = lang.find((l) => l.lang_code === langSt);

      const lang_code = chossen?.lang_code || "en";
      const isRtl = chossen?.rtl || false;

      if (isRtl) {
        setLTL(false);
      }
    }
  }, [loaded]);

  useLayoutEffect(() => {
    getAllLanguages();
    getImages();
  }, []);

  const getImages = useCallback(async () => {
    const res = await Api.getImages();
    if (res.status === 200) {
      console.log(res.data.data);
      setImages(res.data.data);
      // setIsLoading(false);
    }
  }, []);

  return (
    <>
      {/* Navbar */}
      <Header ltl={ltl} setLtl={setLTL} />
      {/* Banner*/}
      <Banner
        ltl={ltl}
        hero={hero}
        image={images.find((img) => img.section === "hero")?.imageUrl}
      />

      {/* How it Works */}
      <Working ltl={ltl} working={working} />

      {/* Share Your Digital Business Card */}
      <BusinessInfo
        ltl={ltl}
        business={business}
        image={images.find((img) => img.section === "business")?.imageUrl}
      />

      {/* Collection */}
      <Collection ltl={ltl} />

      {/* CustomerTestimonials */}
      <CustomerTestimonials ltl={ltl} testimonials={testimonials} />

      {/* Pricing Plans */}
      <div className="bLightBlue">
        <section className="max-w-screen-xl m-auto section bLightBlue">
          <div className="headWrapper">
            <div className="headDiv">
              <h1 className="hHead">{t("pricingPlans")}</h1>
              <p className={ltl ? "headingLineImg" : "headingLineImgRTL"}>
                <img src={headingLine} alt="" />
              </p>
            </div>
          </div>

          <div className="hpParent">
            <div className="hpChild bWhite">
              <h2 className="text-center hpTitle">{t("paymentType1Name")}</h2>
              <h1 className="text-center hpPrice">
                ${t("paymentType1Price")} <span className="hpPriceY">/mo</span>
              </h1>
              <p className="text-center hpDesc">{t("paymentType1Desc")}</p>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink1")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    <i className="bi bi-check-circle-fill checkIcon"></i>
                  </h3>
                </div>
              </div>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink2")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    <i className="bi bi-check-circle-fill checkIcon"></i>
                  </h3>
                </div>
              </div>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink3")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    {/*<i className="bi bi-check-circle-fill checkIcon"></i>*/}
                  </h3>
                </div>
              </div>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink4")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    {/*<i className="bi bi-check-circle-fill checkIcon"></i>*/}
                  </h3>
                </div>
              </div>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink5")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    {/*<i className="bi bi-check-circle-fill checkIcon"></i>*/}
                  </h3>
                </div>
              </div>

              <p className="text-center">
                <button className="hpBtn">{t("getNow")}</button>
              </p>
            </div>
            <div className="hpChild bWhite">
              <h2 className="text-center hpTitle">{t("paymentType2Name")}</h2>
              <h1 className="text-center hpPrice">
                ${t("paymentType2Price")} <span className="hpPriceY">/mo</span>
              </h1>
              <p className="text-center hpDesc">{t("paymentType2Desc")}</p>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink1")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    <i className="bi bi-check-circle-fill checkIcon"></i>
                  </h3>
                </div>
              </div>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink2")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    <i className="bi bi-check-circle-fill checkIcon"></i>
                  </h3>
                </div>
              </div>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink3")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    <i className="bi bi-check-circle-fill checkIcon"></i>
                  </h3>
                </div>
              </div>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink4")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    {/*<i className="bi bi-check-circle-fill checkIcon"></i>*/}
                  </h3>
                </div>
              </div>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink5")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    {/*<i className="bi bi-check-circle-fill checkIcon"></i>*/}
                  </h3>
                </div>
              </div>

              <p className="text-center">
                <button className="hpBtn">{t("getNow")}</button>
              </p>
            </div>
            <div className="hpChild bWhite">
              <h2 className="text-center hpTitle">{t("paymentType2Name")}</h2>
              <h1 className="text-center hpPrice">
                ${t("paymentType3Price")} <span className="hpPriceY">/mo</span>
              </h1>
              <p className="text-center hpDesc">{t("paymentType2Desc")}</p>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink1")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    <i className="bi bi-check-circle-fill checkIcon"></i>
                  </h3>
                </div>
              </div>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink2")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    <i className="bi bi-check-circle-fill checkIcon"></i>
                  </h3>
                </div>
              </div>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink3")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    <i className="bi bi-check-circle-fill checkIcon"></i>
                  </h3>
                </div>
              </div>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink4")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    <i className="bi bi-check-circle-fill checkIcon"></i>
                  </h3>
                </div>
              </div>
              <div className={ltl ? "hpPDParent" : "hpPDParentRTL"}>
                <div className="hpPDChild1">
                  <p className={ltl ? "hpPDesc" : "hpPDescRTL"}>
                    {t("paymentLink5")}
                  </p>
                </div>
                <div className="hpPDChild2">
                  <h3 className="hpPDIcon text-center">
                    <i className="bi bi-check-circle-fill checkIcon"></i>
                  </h3>
                </div>
              </div>

              <p className="text-center">
                <button className="hpBtn">{t("getNow")}</button>
              </p>
            </div>
          </div>
        </section>
      </div>

      {/* Footer */}
      <Footer ltl={ltl} />
    </>
  );
}

export default HomeComponent;

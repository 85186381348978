import { useMemo, useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import { designCard } from "../store/slices/cardSlice";
import Api from "../api/api";
import CardPreview from "../components/preview/preview";
import Loader from "../components/loader/loader";
import { langLoader } from "../store/slices/langSlice";

const PreviewCard = () => {
  const { title } = useParams();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    isLoading: true,
    isCard: true,
    og: { title: "", logo: "", color: "", bg: "" },
  });

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const res = await Api.getCardPublic(title);
        if (res.status === 200) {
          const { data } = res.data;
          if (data.language.rtl) {
            document.querySelector("body").classList.add("preview_rtl");
          }
          setState((prevState) => ({
            ...prevState,
            og: {
              title: data.title,
              logo: data.logo,
              color: data.text_color,
              bg: data.bg_color,
            },
          }));
          dispatch(designCard(data));
        } else if (res.status === 404) {
          setState((prevState) => ({ ...prevState, isCard: false }));
        }
      } catch (error) {
        console.error("Error fetching card data:", error);
      } finally {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    };

    dispatch(langLoader(false));
    fetchCardData();
    return () => {
      document.querySelector("body").classList.remove("preview_rtl");
      document.querySelector("body").classList.remove("rtl");
    };
  }, [title, dispatch]);

  useEffect(() => {
    const createManifest = () => {
      if (
        !state.og.title ||
        !state.og.color ||
        !state.og.bg ||
        !state.og.logo
      ) {
        console.error("Manifest data is incomplete.");
        return;
      }
      const manifestData = {
        name: state.og.title,
        short_name: state.og.title,
        start_url: window.location.href,
        theme_color: state.og.color,
        background_color: state.og.bg,
        display: "standalone",
        prefer_related_applications: true,
        icons: [
          {
            src: state.og.logo,
            sizes: "180x180",
            type: "image/png",
            purpose: "any maskable",
          },
        ],
      };
      const manifestBlob = new Blob([JSON.stringify(manifestData)], {
        type: "application/json",
      });
      const manifestURL = URL.createObjectURL(manifestBlob);
      const existingManifest = document.querySelector('link[rel="manifest"]');
      if (existingManifest) {
        existingManifest.href = manifestURL;
      } else {
        const link = document.createElement("link");
        link.rel = "manifest";
        link.href = manifestURL;
        document.head.appendChild(link);
      }
    };

    const iosSupportTags = () => {
      const appleTouchIcon = document.querySelector(
        'link[rel="apple-touch-icon"]'
      );
      if (appleTouchIcon) {
        appleTouchIcon.href = state.og.logo;
      } else {
        const link = document.createElement("link");
        link.rel = "apple-touch-icon";
        link.href = state.og.logo;
        document.head.appendChild(link);
      }

      const appleMetaTags = [
        { name: "apple-mobile-web-app-capable", content: "yes" },
        { name: "apple-mobile-web-app-status-bar-style", content: "default" },
        { name: "apple-mobile-web-app-title", content: state.og.title },
      ];

      appleMetaTags.forEach(({ name, content }) => {
        const existingMetaTag = document.querySelector(`meta[name="${name}"]`);
        if (existingMetaTag) {
          existingMetaTag.content = content;
        } else {
          const meta = document.createElement("meta");
          meta.name = name;
          meta.content = content;
          document.head.appendChild(meta);
        }
      });
    };

    if (state.og.title && state.og.color && state.og.bg && state.og.logo) {
      createManifest();
      iosSupportTags();
    }
  }, [state.og]);

  return (
    <>
      {state.isLoading && <Loader />}
      {state.isCard ? (
        <>
          <Helmet>
            <title>{state.og.title}</title>
            <meta name="description" content={state.og.title} />
            <link rel="icon" type="image/png" href={state.og.logo} />
            <meta name="theme-color" content={state.og.color} />
          </Helmet>
          <div className="preview_page">
            <CardPreview />
          </div>
        </>
      ) : (
        <p className="w-full text-center pt-20">No Card Exists</p>
      )}
    </>
  );
};

export default PreviewCard;

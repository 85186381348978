import React from "react";

const WeVisitor = () => {
  return (
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_367_17)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.495 6C51.127 6 62.989 17.862 62.989 32.495C62.989 47.127 51.127 58.989 36.495 58.989C21.862 58.989 10 47.127 10 32.495C10 17.862 21.862 6 36.495 6Z"
          fill="#FFBB2A"
        ></path>
      </g>
      <path
        d="M36.495 6C51.127 6 62.989 17.862 62.989 32.495C62.989 47.127 51.127 58.989 36.495 58.989C21.862 58.989 10 47.127 10 32.495C10 17.862 21.862 6 36.495 6Z"
        fill="url(#paint0_linear_367_17)"
      ></path>
      <path
        opacity="0.502"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.492 32.2952C31.082 32.2952 29.939 31.1272 29.939 29.6852C29.939 28.2442 31.082 27.0762 32.492 27.0762C33.902 27.0762 35.045 28.2442 35.045 29.6852C35.045 31.1272 33.902 32.2952 32.492 32.2952ZM34.778 33.6622C34.648 33.7822 34.516 33.8992 34.393 34.0282C33.575 34.8872 32.968 35.9322 32.62 37.0772C32.501 37.4542 32.413 37.8402 32.357 38.2332H28.587C28.255 38.2332 27.986 37.9582 27.986 37.6192C27.989 35.0762 30.005 33.0162 32.492 33.0132C33.297 33.0162 34.087 33.2412 34.778 33.6622Z"
        fill="white"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.407 39.9702H34.078C33.746 39.9702 33.477 39.6952 33.477 39.3562C33.475 38.7052 33.573 38.0582 33.768 37.4382C34.059 36.4802 34.568 35.6062 35.252 34.8872C37.669 32.3532 41.637 32.3022 44.116 34.7722C45.325 35.9772 46.007 37.6302 46.008 39.3562C46.008 39.6952 45.739 39.9702 45.407 39.9702ZM39.743 31.9882C37.847 31.9882 36.31 30.4172 36.31 28.4792C36.31 26.5412 37.847 24.9702 39.743 24.9702C41.639 24.9702 43.176 26.5412 43.176 28.4792C43.174 30.4172 41.638 31.9862 39.743 31.9882Z"
        fill="white"
      ></path>
      <defs>
        <filter
          id="filter0_d_367_17"
          x="0.202"
          y="0.202"
          width="72.585"
          height="72.585"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="4.899"></feGaussianBlur>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.968627 0 0 0 0 0.686275 0 0 0 0 0.0862745 0 0 0 0.32 0"
          ></feColorMatrix>
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_367_17"
          ></feBlend>
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_367_17"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_367_17"
          x1="10"
          y1="58.989"
          x2="10"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7AF16"></stop>
          <stop offset="1" stopColor="#FFCC5F"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WeVisitor;

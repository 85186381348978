import React from "react";

const ReviewsIcon = () => {
  return (
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_367_10)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.499 6.01489C51.131 6.01489 62.993 17.8769 62.993 32.5099C62.993 47.1419 51.131 59.0039 36.499 59.0039C21.866 59.0039 10.004 47.1419 10.004 32.5099C10.004 17.8769 21.866 6.01489 36.499 6.01489Z"
          fill="#50ECA6"
        ></path>
      </g>
      <path
        d="M36.499 6.01489C51.131 6.01489 62.993 17.8769 62.993 32.5099C62.993 47.1419 51.131 59.0039 36.499 59.0039C21.866 59.0039 10.004 47.1419 10.004 32.5099C10.004 17.8769 21.866 6.01489 36.499 6.01489Z"
        fill="url(#paint0_linear_367_10)"
        fillOpacity="0.32"
      ></path>
      <path
        opacity="0.502"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.324 25.66L40.156 26.828C39.815 27.169 39.261 27.169 38.919 26.828C38.577 26.486 38.577 25.932 38.919 25.59L40.086 24.423C40.428 24.081 40.982 24.081 41.324 24.423C41.666 24.765 41.666 25.319 41.324 25.66ZM34.87 25.332C34.386 25.332 33.995 24.941 33.995 24.457V22.707C33.995 22.224 34.386 21.833 34.87 21.833C35.353 21.833 35.745 22.224 35.745 22.707V24.457C35.745 24.941 35.353 25.332 34.87 25.332ZM30.823 26.827C30.482 27.168 29.927 27.168 29.586 26.827L28.418 25.659C28.076 25.318 28.076 24.764 28.418 24.422C28.76 24.08 29.314 24.08 29.656 24.422L30.823 25.589C31.165 25.931 31.165 26.485 30.823 26.827ZM29.327 30.874C29.327 31.357 28.936 31.749 28.452 31.749H26.702C26.219 31.749 25.827 31.357 25.827 30.874C25.827 30.391 26.219 29.999 26.702 29.999H28.452C28.936 29.999 29.327 30.391 29.327 30.874ZM29.584 34.923C29.926 34.582 30.48 34.582 30.822 34.923V34.924C31.164 35.265 31.164 35.819 30.822 36.161L29.655 37.328C29.313 37.67 28.759 37.67 28.417 37.328C28.075 36.986 28.075 36.432 28.417 36.091L29.584 34.923Z"
        fill="white"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.78 37.9771L40.985 35.1831L42.984 34.3001C43.465 34.0811 43.837 33.6781 44.016 33.1811C44.196 32.6841 44.168 32.1361 43.937 31.6601C43.707 31.1851 43.295 30.8221 42.794 30.6541L34.29 27.8191C33.827 27.6641 33.323 27.6881 32.876 27.8861C32.43 28.0831 32.072 28.4401 31.875 28.8871C31.677 29.3331 31.654 29.8381 31.808 30.3011V30.2941L34.65 38.7961C34.775 39.1721 35.011 39.5011 35.326 39.7401C35.641 39.9801 36.021 40.1181 36.416 40.1371H36.511C36.891 40.1371 37.262 40.0271 37.58 39.8211C37.898 39.6141 38.149 39.3191 38.303 38.9731L39.177 36.9941L41.97 39.7861C42.47 40.2861 43.28 40.2861 43.78 39.7861C44.279 39.2871 44.279 38.4771 43.78 37.9771Z"
        fill="white"
      ></path>
      <defs>
        <filter
          id="filter0_d_367_10"
          x="0.206028"
          y="0.216892"
          width="72.585"
          height="72.585"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="4.899"></feGaussianBlur>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.266667 0 0 0 0 0.886275 0 0 0 0 0.607843 0 0 0 0.32 0"
          ></feColorMatrix>
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_367_10"
          ></feBlend>
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_367_10"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_367_10"
          x1="10.004"
          y1="59.0039"
          x2="10.004"
          y2="6.01489"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3BDF95"></stop>
          <stop offset="1" stopColor="#7FF9C2"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ReviewsIcon;

import "./profile.css";
import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import Cookies from "js-cookie";
import Button from "../../common/Button";
import camera from "../../assets/camera.webp";
import Api from "../../api/api";
import Loader from "../loader/loader";
import { Notifications } from "../../helper/notifications";
import {
  JWT_STORAGE_NAME,
  LOCAL_STORAGE_NAME,
  OVERRIDE,
} from "../../utils/constants";

const ProfileComp = () => {
  const language = useSelector((state) => state.language);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisable1, setIsDisable1] = useState(false);
  const [isDisable2, setIsDisable2] = useState(false);
  const [data, setData] = useState({
    avatar: "",
    avatarURL: "",
    name: "",
    email: "",
  });

  const [data2, setData2] = useState({
    prePassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const submitHandle1 = async (e) => {
    e.preventDefault();

    setIsDisable1(true);
    const res = await Api.updateUser(data);
    if (res.status === 200) {
      Notifications("success", res.data.message);
      window.location.reload();
    }
    setIsDisable1(false);
  };

  const submitHandle2 = async (e) => {
    e.preventDefault();
    if (data2.newPassword !== data2.confirmPassword)
      return Notifications("warning", "Confirm Password doesn't match");

    setIsDisable2(true);
    const res = await Api.updatePassword(data2);
    if (res.status === 200) {
      Notifications("success", res.data.message);
      setData2({
        prePassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    }
    setIsDisable2(false);
  };

  const setImageHandle = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setData((prev) => ({
          ...prev,
          avatarURL: event.target.result,
          avatar: event.target.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const inputHandle = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const inputHandle2 = (e) => {
    setData2((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useLayoutEffect(() => {
    async function getMe() {
      const res = await Api.getMyProfile();
      if (res.status === 200) {
        setData({
          avatarURL: res.data.data.avatar,
          name: res.data.data.name,
          email: res.data.data.email,
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      } else if (res.status === 500) {
        Cookies.remove(JWT_STORAGE_NAME);
        localStorage.removeItem(JWT_STORAGE_NAME);
        localStorage.removeItem(LOCAL_STORAGE_NAME);
        window.location.reload();
      }
    }

    getMe();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="profile_page_cont">
          <div className="c_card">
            <div className="title_3">{language.accSettings}</div>
            <form onSubmit={submitHandle1} className="profile_form">
              <Picture image={data.avatarURL} setImageHandle={setImageHandle} />
              <br />
              <div className="profile_input_wrapper">
                <div className="c_input_wrapper">
                  <input
                    type="text"
                    name="name"
                    value={data.name}
                    onChange={inputHandle}
                    placeholder={language.name}
                    required
                  />
                </div>
                <div className="c_input_wrapper">
                  <input
                    type="email"
                    name="email"
                    value={data.email}
                    onChange={inputHandle}
                    placeholder={language.emailAddress}
                    disabled
                  />
                </div>
              </div>

              <Button disabled={isDisable1} type="submit" margin="0 0 8px 0">
                {!isDisable1 ? (
                  language.updateProfile
                ) : (
                  <ClipLoader
                    color="#FFFFFF"
                    loading={true}
                    cssOverride={OVERRIDE}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
              </Button>
            </form>
            <br />
            <form
              onSubmit={submitHandle2}
              className="profile_form password_form"
            >
              <div className="profile_input_wrapper">
                <div className="c_input_wrapper">
                  <input
                    type="password"
                    name="prePassword"
                    value={data2.prePassword}
                    onChange={inputHandle2}
                    placeholder={language.currentPassword}
                    required
                  />
                </div>
              </div>
              <div className="profile_input_wrapper">
                <div className="c_input_wrapper">
                  <input
                    type="password"
                    name="newPassword"
                    value={data2.newPassword}
                    onChange={inputHandle2}
                    placeholder={language.newPassword}
                    required
                  />
                </div>
              </div>
              <div className="profile_input_wrapper">
                <div className="c_input_wrapper">
                  <input
                    type="password"
                    name="confirmPassword"
                    value={data2.confirmPassword}
                    onChange={inputHandle2}
                    placeholder={language.confirmPassword}
                    required
                  />
                </div>
              </div>

              <Button disabled={isDisable2} type="submit" margin="0 0 8px 0">
                {!isDisable2 ? (
                  language.changePassword
                ) : (
                  <ClipLoader
                    color="#FFFFFF"
                    loading={true}
                    cssOverride={OVERRIDE}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
              </Button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

const Picture = ({ image, setImageHandle }) => {
  return (
    <div className="profile_picture">
      <label htmlFor="avatar">
        <img src={image} alt="avatar" />
        {/* <span>
          <img src={camera} alt="" />
        </span> */}
      </label>
      <input
        type="file"
        id="avatar"
        name="logoURL"
        accept="image/*"
      
        onChange={setImageHandle}
      />
    </div>
  );
};

export default ProfileComp;

import React from "react";
import banner from "../../assets/Group 1000004352.webp";
import stars from "../../assets/Stars.webp";
import line from "../../assets/Line 29.webp";
import { useTranslation } from "react-i18next";

function Banner({ ltl, hero, image }) {
  const col = !ltl ? "md:order-2" : "md:order-1";
  const col2 = !ltl ? "md:order-1" : "md:order-2";
  const { t } = useTranslation();

  return (
    <div className="bg-white flex justify-center ">
      <div className="max-w-screen-xl  grid grid-cols-1 md:grid-cols-5 bg-white ">
        <div
          className={
            "col-span-1 md:col-span-3 my-3 h-full flex flex-col justify-center  mx-6 md:mx-32 " +
            col
          }
        >
          <div className="my-3 tracking-tighter ">
            <span className="text-2xl md:text-4xl font-semibold">
              {t("heroTitle")}
            </span>
          </div>
          {/* <div className="my-3 tracking-tighter ">
          <span className="text-2xl md:text-4xl font-semibold">Digital</span>
          <span className="ml-2 text-blue-600 text-2xl md:text-4xl font-semibold ">
            Business Card
          </span>
        </div>

        <div className="mb-2 tracking-tighter ">
          <span className="text-2xl md:text-4xl font-semibold ">
            Get Marketing .
          </span>
        </div> */}
          <div className="text-sm">
            <p>{t("heroDescription")}</p>

            <div className="flex flex-row my-3">
              <div className="flex flex-col">
                <img src={stars} className="w-24 h-4" />
                <span className="text-xs font-thin text-gray-600 my-2">
                  Based on 541 reviews (4.9/5)
                </span>
                <button className="bg-blue-600 text-xs text-white font-thin px-8 py-3 rounded-md ">
                  {t("getAQuote")}
                </button>
              </div>
              <div>
                {" "}
                <img src={line} className="h-full w-8 ml-6" />
              </div>
            </div>
          </div>
        </div>

        <div
          className={" col-span-1 md:col-span-2 flex justify-center " + col2}
        >
          <img
            src={image}
            className=" w-auto md:w-auto h-[60vh] object-cover"
          />
        </div>
      </div>
    </div>
  );
}

export default Banner;

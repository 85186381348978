import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { parsePhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-number-input";
import { designCard } from "../../../store/slices/cardSlice";
import DesignCard from "../../../layout/designCard/designCard";
import CustomModal from "../../../layout/modal/modal";
import Api from "../../../api/api";
import { editSettings } from "../../../store/slices/cardSlice";

const Floating = ({ toggle, setToggle }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.card);
  const language = useSelector((state) => state.language);
  const [iconsList, setIconsList] = useState([]);
  const [editModal, setEditModal] = useState(false);

  const inputHandle = async (name, value) => {
    dispatch(designCard({ ...state, [name]: value }));

    if (name === "floating_icon") {
      try {
        const response = await fetch(value);
        const blob = await response.blob();

        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64String = reader.result;
          const formData = {
            id: state.id,
            image: base64String,
          };
          await Api.updateFloatingIcon(formData);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const submitColorHandle = async (e) => {
    const { name, value } = e.target;
    await Api.updateFloatingBg({ id: state.id, [name]: value });
  };

  const editNumberHandle = async (e) => {
    const parsedPhoneNumber = parsePhoneNumber(e.nativeEvent.target.value);
    if (!parsedPhoneNumber.isValid()) return alert("Invalid number");

    await Api.updateNumber({
      id: state.id,
      number: e.nativeEvent.target.value,
    });
  };

  const getIconsList = useCallback(async () => {
    const res = await Api.getFloatingIcons();
    if (res.status === 200) {
      setIconsList(res.data.data);
    }
  }, [setIconsList]);

  useEffect(() => {
    getIconsList();
  }, []);
  const settingsHandle = async (key) => {
    dispatch(
      editSettings({
        ...state,
        settings: { ...state.settings, [key]: !state.settings[key] },
      })
    );

    await Api.updateSettings({
      ...state.settings,
      [key]: !state.settings[key],
    });
  };
  return (
    <DesignCard
      title={language.floatingNumber}
      toggle={toggle}
      onShow={() => setToggle(7, !toggle)}
      language={language.floatingButton}
      value={state.settings.floating}
      settingsHandle={() => settingsHandle("floating")}
    >
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-3">
        <ColorInput
          type="color"
          className="px-5"
          label={language.backgroundColor}
          name="floating_bg"
          value={state.floating_bg}
          inputHandle={(e) => inputHandle("floating_bg", e.target.value)}
          submitData={submitColorHandle}
        />
        <Number
          value={state.number}
          inputHandle={inputHandle}
          editNumberHandle={editNumberHandle}
          placeholder="Business number"
          icon={state.floating_icon}
          onEdit={() => setEditModal(true)}
        />
      </div>

      {editModal && (
        <EditIcons
          language={language}
          data={iconsList}
          setIcon={inputHandle}
          onHide={() => setEditModal(false)}
        />
      )}
    </DesignCard>
  );
};

const Number = memo(
  ({ icon, value, placeholder, onEdit, inputHandle, editNumberHandle }) => {
    const { name } = useParams();
    const countryCode = useMemo(() => {
      if (name) {
        const parts = name.split(".");
        const code = parts[parts.length - 1];
        return code.toUpperCase();
      }
    }, [name]);

    return (
      <div
        className="design_wrapper floating_number_wrapper"
        style={{ display: "flex", alignItems: "center", gap: 12 }}
      >
        <div className="icon_wrapper">
          <img onClick={onEdit} src={icon} alt="" />
        </div>
        <div className="main_icon_inputs">
          <PhoneInput
            value={value}
            onChange={(e) => inputHandle("number", e)}
            onBlur={editNumberHandle}
            placeholder={placeholder}
            defaultCountry={countryCode}
            countryCallingCodeEditable={false}
            international
            required
          />
        </div>
      </div>
    );
  }
);

const EditIcons = ({ data, onHide, setIcon, language }) => {
  return (
    <CustomModal title={language.editIcon} onHide={onHide}>
      <div className="icons_cont">
        {data &&
          data.map(({ imageUrl }, index) => (
            <img
              key={index}
              onClick={() => [onHide(), setIcon("floating_icon", imageUrl)]}
              src={imageUrl}
              alt="Icons"
            />
          ))}
      </div>
    </CustomModal>
  );
};

const ColorInput = ({ type, label, name, value, inputHandle, submitData }) => {
  return (
    <div className="design_input_wrapper ">
      <label className="flex justify-between gap-1 font-semibold pb-2">
        {label}
      </label>
      <div className="flex px-3 py-1 border border-solid border-gray-300 rounded-lg text-center items-center justify-between">
        <div className="w-12 rounded-lg">
          <input
            type={type}
            name={name}
            value={value}
            onChange={inputHandle}
            onBlur={submitData}
            className="w-full rounded-lg border-none focus:ring-0"
          />
        </div>
        <label>{value}</label>
      </div>
    </div>
  );
};
export default Floating;

import "./modal.css";
import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 400px;
  height: auto;
  padding: 14px;
  border-radius: var(--border_radius);

  color: ${({ color }) => color};

  & .bi::before {
    color: ${({ color }) => color};
    font-weight: 600 !important;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    & {
      width: 330px;
    }
  }
`;

const Booking = ({ children, rtl, onHide }) => {
  const modalRef = useRef();

  return createPortal(
    <>
      <div className="custom_modal_overlay"></div>
      <Modal
        ref={modalRef}
        className="custom_modal_cont bg-opacity-60 bg-black"
      >
        <div
          className="custom_modal_head "
          style={{ flexDirection: rtl && "row-reverse" }}
        >
          <p></p>
          <span onClick={onHide}>
            <i className="bi bi-x-lg text-white"></i>
          </span>
        </div>
        <div className="custom_modal_body">{children}</div>
      </Modal>
    </>,
    document.getElementById("portal")
  );
};

export default Booking;

import Switch from "react-switch";
import { useSelector } from "react-redux";
import "./designCard.css";

const DesignCard = ({
  children,
  title,
  toggle,
  onShow,
  label,
  value,
  settingsHandle,
}) => {
  const language = useSelector((state) => state.language);
  return (
    <div className="c_card card_design">
      <div className="c_head flex justify-between">
        <div className="flex w-full" onClick={onShow}>
          <span className="cursor-pointer w-full">{title}</span>
        </div>
        <div className="flex gap-3">
          {title !== language.cardDesign && (
            <Option label={label} value={value} onChange={settingsHandle} />
          )}
          <span
            style={{ transform: `rotate(${toggle ? 180 : 0}deg)` }}
            onClick={onShow}
          >
            <i className="bi bi-caret-down-fill "></i>
          </span>
        </div>
      </div>
      {toggle && <div className="c_body">{children}</div>}
    </div>
  );
};
const Option = ({ label, value, onChange }) => {
  return (
    <div
      className="gen_setting"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <p>{label}</p>
      <Switch
        checked={value}
        onChange={onChange}
        width={42}
        height={20}
        onColor="#2563EB"
        handleDiameter={16}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      />
    </div>
  );
};
export default DesignCard;

import React from "react";
import stars from "../../assets/Stars.webp";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import headingLine from "../../assets/homepage/headingLine.webp";

// import required modules

import "./swiper.css";

// import required modules
import { Autoplay, Grid } from "swiper/modules";
import { t } from "i18next";
function CustomerTestimonials({ ltl, testimonials }) {
  const data = testimonials;

  return (
    <>
      <div className="bg-white ">
        <div className="max-w-screen-xl m-auto bg-white min-h-[50vh]  p-8">
          <div className="headWrapper">
            <div className="headDiv">
              <h1 className="hHead">{t("customerTestimonials")}</h1>
              <p className={ltl ? "headingLineImg" : "headingLineImgRTL"}>
                <img src={headingLine} alt="" />
              </p>
            </div>
          </div>
          <Swiper
            spaceBetween={30}
            slidesPerView={3}
            grid={{
              rows: 1,
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              300: {
                slidesPerView: 1,
              },
              550: {
                slidesPerView: 2,
              },

              800: {
                slidesPerView: 3,
              },
            }}
            modules={[Autoplay]}
          >
            {data.map((user) => {
              return (
                <SwiperSlide>
                  <div className="rounded-lg shadow-lg p-5 mb-10  h-auto md:h-[200px] text-left">
                    <img src={stars} className="w-24 h-4 mb-2" />
                    <span className="text-xs  text-gray-700">
                      {user.description}
                    </span>
                    <h1 className="text-sm font-semibold my-2">{user.name}</h1>
                    <span className="text-xs  text-gray-700">
                      {user.company}
                    </span>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default CustomerTestimonials;

import Home from "../pages/Home";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import Forget from "../pages/Forget";
import Reset from "../pages/Reset";
import EditCard from "../pages/EditCard";
import PreviewCard from "../pages/PreviewCard";
import Profile from "../pages/Profile";
import Reviews from "../pages/Reviews";
import PendingReviews from "../pages/PendingReviews";

export const PUBLIC_ROUTES = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/preview/:title",
    component: PreviewCard,
  },
];

export const PROTECTED_ROUTES = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/card/:title",
    component: EditCard,
  },
  {
    path: "/reviews/approved",
    component: Reviews,
  },
  {
    path: "/reviews/pending",
    component: PendingReviews,
  },
  {
    path: "/account",
    component: Profile,
  },
];

export const PROTECTED_AUTH = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/signup",
    component: Signup,
  },
  {
    path: "/forget",
    component: Forget,
  },
  {
    path: "/reset",
    component: Reset,
  },
];

import "./Header.css";
import {
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Usermenu from "../usermenu/Usermenu";
import Api from "../../api/api";
import { LANG_NAME } from "../../utils/constants";
import { addLang, langLoader } from "../../store/slices/langSlice";
import logo from "../../assets/Mask group.webp";
import { Link } from "react-router-dom";
import { LINKS } from "../../utils/links";
import Cookies from "js-cookie";
import { JWT_STORAGE_NAME, LOCAL_STORAGE_NAME } from "../../utils/constants";

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const showUserMenuRef = useRef();
  const showMenuRef = useRef();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [languagesData, setLanguagesData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem(LANG_NAME) || "English"
  );
  const handleMouseEnter = (item) => {
    setActiveItem(item);
    setShowMenu(!showMenu);
  };
  const handleEnter = (item) => {
    setActiveItem(item);
    setShowNav(!showNav);
  };
  const langHandle = async (e) => {
    const lang_code = e.target.value;
    setSelectedLang(lang_code);
    localStorage.setItem(LANG_NAME, lang_code);
    dispatch(langLoader(true));
    window.location.reload();
  };
  const toggleNavbar = (isBoolen) => {
    setIsExpanded(!isBoolen);
  };

  const getAllLanguages = useCallback(async () => {
    const res = await Api.getAllLanguages();
    if (res.status === 200) {
      setLanguagesData(res.data.data);
    }
  }, [setLanguagesData]);

  useLayoutEffect(() => {
    getAllLanguages();
  }, []);
  const language = useSelector((state) => state.language);
  const activeLink = (id) => {
    if (id === window.location.pathname) return "active";
  };

  const logoutHandler = () => {
    Cookies.remove(JWT_STORAGE_NAME);
    localStorage.removeItem(JWT_STORAGE_NAME);
    localStorage.removeItem(LOCAL_STORAGE_NAME);
    window.location.href = "/";
  };
  useEffect(() => {
    let handler = (e) => {
      if (!showMenuRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => document.removeEventListener("mousedown", handler);
  }, []);
  return (
    <header className="bg-white mb-12">
      <div className="header_cont max-w-[1200px] mx-auto h-20 px-4">
        <div className="flex items-center justify-center">
          <img src={logo} className="h-10 mr-3" alt="Logo" />
          <span className="self-center text-2xl text-blue-500 font-semibold">
            Digital Idea
          </span>
        </div>
        <ul
          className="hidden md:flex items-center justify-center "
          ref={showMenuRef}
        >
          {LINKS.map((item, index) => (
            <>
              <li key={index} className="px-4 py-2 relative">
                <a
                  className="hover:text-orange-500 cursor-pointer text-sm text-[#303030]"
                  href={item.href}
                  onClick={() => handleMouseEnter(item)}
                >
                  {language[item.translation]}
                </a>
                {item.subItems &&
                  item.subItems.length > 0 &&
                  activeItem === item &&
                  showMenu && (
                    <div className="absolute left-0 bg-white rounded-md shadow-md flex flex-col px-2 py-2 ">
                      {item.subItems.map((subItems, subIndex) => (
                        <a
                          className="text-[#022a3a] text-sm p-2 hover:rounded-md hover:bg-orange-100 hover:text-orange-500 cursor-pointer"
                          href={subItems.href}
                        >
                          {subItems.name}
                        </a>
                      ))}
                    </div>
                  )}
              </li>
            </>
          ))}
        </ul>
        <div className=" relative hidden md:flex items-center gap-3">
          <div className="lang_select_wrapper">
            <i className="bi bi-globe2 fs-6" style={{ fontSize: "24px" }}></i>
            <select
              value={selectedLang}
              onChange={langHandle}
              className="uppercase"
            >
              {languagesData.map((item) => (
                <option
                  key={item.lang_code}
                  value={item.lang_code}
                  className="uppercase"
                >
                  {item.lang_code}
                </option>
              ))}
            </select>
          </div>
          <div className="avatar" onClick={() => setShowUserMenu(true)}>
            <img src={user?.avatar} alt="" />
            {showUserMenu && (
              <Usermenu
                avatar={user.avatar}
                name={user.name}
                email={user.email}
                showUserMenuRef={showUserMenuRef}
                setShowUserMenu={setShowUserMenu}
              />
            )}
          </div>
          <span onClick={() => setShowUserMenu(true)}>{user?.name}</span>
        </div>

        <div className="flex md:hidden text-2xl cursor-pointer">
          <span onClick={() => toggleNavbar(isExpanded)}>
            <i className="bi bi-list"></i>
          </span>
        </div>
      </div>
      {isExpanded && (
        <div className="flex md:hidden flex-col p-4 border-t ">
          <ul className="flex flex-col">
            {LINKS.map((item, index) => (
              <>
                <a
                  key={index}
                  className={`px-4 py-2 hover:rounded-md  text-[#303030] hover:bg-orange-100 hover:text-orange-500 `}
                  onClick={() => handleEnter(item)}
                  href={item.href}
                >
                  <span className="w-full cursor-pointer text-sm ">
                    {language[item.translation]}
                  </span>
                </a>
                {item.subItems &&
                  item.subItems.length > 0 &&
                  activeItem === item &&
                  showNav && (
                    <div className=" flex flex-col px-2 py-2 ml-5">
                      {item.subItems.map((subItems, subIndex) => (
                        <a
                          className="text-[#022a3a] text-sm p-2 hover:rounded-md hover:bg-orange-100 hover:text-orange-500 cursor-pointer"
                          href={subItems.href}
                        >
                          {subItems.name}
                        </a>
                      ))}
                    </div>
                  )}
              </>
            ))}
          </ul>
          <div className="flex flex-col border-t mt-4">
            <div className="flex items-center px-2 py-2 gap-3">
              <img
                src={user.avatar}
                alt=""
                className="w-10 h-10 rounded-full"
              />
              <div>
                <p>{user.name}</p>
                <span className="text-gray-500">{user.email}</span>
              </div>
            </div>
            <div className="flex gap-2 px-2 py-2 hover:text-orange-500 text-gray-400 hover:bg-orange-100 rounded-md">
              <i className="bi bi-globe2 text-base"></i>
              <select
                value={selectedLang}
                onChange={langHandle}
                className="uppercase"
              >
                {languagesData.map((item) => (
                  <option
                    key={item.lang_code}
                    value={item.lang_code}
                    className="uppercase"
                  >
                    {item.lang_name}
                  </option>
                ))}
              </select>
            </div>
            <a
              href="#"
              onClick={logoutHandler}
              className="flex gap-3 px-2 py-2 hover:bg-orange-100 rounded-md text-base hover:text-orange-500 text-gray-400"
            >
              <i className="bi bi-box-arrow-right cursor-pointer  "></i>
              {language.logout}
            </a>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;

import React, { memo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { designCard } from "../../../store/slices/cardSlice";
import Api from "../../../api/api";
import Skeleton from "@mui/material/Skeleton";
import styled from "styled-components";
import bell from "../../../assets/bell.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const CardCover = ({
  showCover,
  showLikeBtn,
  coverPhoto,
  coverColor,
  likes,
  uid,
  views,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.card);
  const [isLiked, setIsLiked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const Modal = styled.div`
    position: absolute;
    top: 30px;
    left: 30px;
    right: 30px;
    z-index: 1000;
    height: auto;
    padding: 5px;
    background-color: ${({ bg }) => bg};
    color: ${({ color }) => color};

    & .bi::before {
      color: rgb(185 28 28 / var(--tw-border-opacity));
      font-weight: 700 !important;
      cursor: pointer;
    }
  `;

  const likeHandle = async () => {
    let prevData = JSON.parse(localStorage.getItem("cardLikes")) || [];
    const titleExists = prevData.some((item) => item.title === state.URL_title);
    let data = [];

    if (!window.location.pathname.includes("/card/")) {
      setIsLiked((prev) => !prev);

      if (!isLiked) {
        if (!titleExists) {
          dispatch(designCard({ ...state, likes: state.likes + 1 }));
          data = [...prevData, { title: state.URL_title }];
          localStorage.setItem("cardLikes", JSON.stringify(data));
          await Api.likeUnlikeCard({
            URL_title: state.URL_title,
            status: true,
          });
        }
      } else {
        if (titleExists) {
          dispatch(
            designCard({
              ...state,
              likes: state.likes <= 0 ? 0 : state.likes - 1,
            })
          );
          data = prevData.filter((item) => item.title !== state.URL_title);
          localStorage.setItem("cardLikes", JSON.stringify(data));
          await Api.likeUnlikeCard({
            URL_title: state.URL_title,
            status: false,
          });
        }
      }
    }
  };

  const updateViewCount = async () => {
    if (!window.location.pathname.includes("/preview/")) return;
    let prevData = JSON.parse(localStorage.getItem("cardViews")) || [];
    const titleExists = prevData.some((item) => item.title === state.URL_title);
    let data = [];

    if (!titleExists) {
      const res = await Api.updateViewCount(state.URL_title);
      if (res.status === 200) {
        data = [...prevData, { title: state.URL_title }];
        localStorage.setItem("cardViews", JSON.stringify(data));
      }
    }
  };

  const getAlert = async () => {
    const res = await Api.getAlertById(uid);
    const dataList = res.data.data;
    if (dataList.length) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();

      const filteredAlerts = dataList.filter((data) => {
        const alertDate = new Date(data.date);
        const alertYear = alertDate.getFullYear();
        const alertMonth = alertDate.getMonth();
        const alertDay = alertDate.getDate();
        return (
          currentYear === alertYear &&
          currentMonth === alertMonth &&
          currentDay === alertDay
        );
      });
      if (filteredAlerts.length) {
        setAlerts(filteredAlerts);
        // setTimeout(() => {
        setShowModal(new Array(filteredAlerts.length).fill(true));
        // }, 80000);
      }
    }
  };
  const handleNotificationClick = (url) => {
    console.log(url);
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (state.URL_title) {
      let prevData = JSON.parse(localStorage.getItem("cardLikes")) || [];
      const titleExists = prevData.some(
        (item) => item.title === state.URL_title
      );
      if (titleExists) setIsLiked(true);

      updateViewCount();
      setTimeout(() => {
        getAlert();
      }, 10000);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [state.URL_title]);

  const handleCloseModal = async (index, count, id) => {
    setShowModal((prev) => {
      const newShowModal = [...prev];
      newShowModal[index] = false;
      return newShowModal;
    });
    const counts = count - 1;
    await Api.updateAlertCount({ id, counts });
  };

  return (
    <div className="cover_image_wrapper" style={{ background: "transparent" }}>
      {isLoading ? (
        <div className="flex flex-col justify-center">
          <Skeleton variant="rounded" height={236} />
        </div>
      ) : (
        <>
          {showCover && coverPhoto && (
            <img
              src={coverPhoto}
              alt=""
              loading="lazy"
              width={530}
              height={250}
            />
          )}
          {alerts.map(
            (icon, index) =>
              icon.count > 0 &&
              showModal[index] && (
                <Modal
                  key={index}
                  className="rounded-2xl border-red-700 border"
                  bg={"#FFFFFF"}
                  color={"#000"}
                >
                  <div className="flex justify-between">
                    <div
                      className="flex gap-2 cursor-pointer"
                      onClick={() => handleNotificationClick(icon.url)}
                    >
                      <div className="flex h-9 w-10 relative">
                        <img
                          src={bell}
                          alt="Logo"
                          height={36}
                          width={40}
                          className="!object-fill"
                        />
                        <div className=" absolute left-0 top-2 rounded-full bg-red-500 w-3 h-3 flex items-center justify-center p-2">
                          <p className="text-xs font-bold text-white">
                            {index + 1}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-1 items-center">
                        <div className="p-2 min-w-[80px] h-20">
                          <img
                            src={icon.imageUrl}
                            width="96px"
                            height="96px"
                            alt="gallery"
                            className="!rounded-none"
                          />
                        </div>
                        <p className="text-base font-bold w-[60%]">
                          {icon.description}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col pr-2 gap-2">
                      <span
                        className="flex justify-end"
                        onClick={() =>
                          handleCloseModal(index, icon.count, icon.id)
                        }
                      >
                        <i
                          className="bi bi-x-lg"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </span>
                      <span className="relative flex h-5 w-5">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-5 w-5 bg-green-500"></span>
                      </span>
                    </div>
                  </div>
                </Modal>
              )
          )}
          {showLikeBtn && (
            <div className="like_wrapper h-7" onClick={likeHandle}>
              <span>
                {!isLiked ? (
                  <i className="bi bi-hand-thumbs-up w-3.5 h-3.5"></i>
                ) : (
                  <i className="bi bi-hand-thumbs-up-fill w-3.5 h-3.5"></i>
                )}
              </span>
              <span className="text-sm">{likes}</span>
            </div>
          )}
          <div className="view_wrapper h-7 justify-between">
            <span className="text-sm">{views}</span>
            <FontAwesomeIcon icon={faEye} />
          </div>
        </>
      )}
    </div>
  );
};

export default memo(CardCover);

import "./builder.css";
import { useState, useCallback, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import CardDesign from "./options/design";
import Gallery from "./options/gallery";
import MainIcons from "./options/icons";
import OperatingHours from "./options/hours";
import Reviews from "./options/reviews";
import General from "./options/general";
import CardPreview from "../preview/preview";
import Floating from "./options/floating";
import Button from "../../common/Button";
import Appointment from "./options/appointment";
import Api from "../../api/api";
import { updateBooking } from "../../store/slices/cardSlice";
import phone from "../../assets/phone_type.webp";

const CardBuilder = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { URL_title } = useSelector((state) => state.card);
  const language = useSelector((state) => state.language);
  const state = useSelector((state) => state.card);

  const fetchBooking = useCallback(async () => {
    const res = await Api.getBookingById(state.id);
    const bookingdata = res.data.data;
    if (bookingdata && res.status === 200) {
      const booking = {
        cid: bookingdata.cid,
        name: bookingdata.name,
        email: bookingdata.email,
        phone: bookingdata.phone,
        booking: bookingdata.booking,
      };
      dispatch(updateBooking(booking));
    }
  }, []);
  useLayoutEffect(() => {
    fetchBooking();
  }, []);

  const [showOptions, setShowOptions] = useState({
    design: false,
    icons: false,
    hours: false,
    gallery: false,
    reviews: false,
    general: false,
    floating: false,
  });

  const handleToggle = (id, bool) => {
    if (id === 1) {
      setShowOptions({
        design: bool,
        icons: false,
        hours: false,
        gallery: false,
        reviews: false,
        general: false,
        floating: false,
        appointment: false,
      });
    } else if (id === 2) {
      setShowOptions({
        design: false,
        icons: bool,
        gallery: false,
        reviews: false,
        general: false,
        floating: false,
        appointment: false,
      });
    } else if (id === 3) {
      setShowOptions({
        design: false,
        icons: false,
        hours: bool,
        gallery: false,
        reviews: false,
        general: false,
        floating: false,
        appointment: false,
      });
    } else if (id === 4) {
      setShowOptions({
        design: false,
        icons: false,
        hours: false,
        gallery: bool,
        reviews: false,
        general: false,
        floating: false,
        appointment: false,
      });
    } else if (id === 5) {
      setShowOptions({
        design: false,
        icons: false,
        hours: false,
        gallery: false,
        reviews: bool,
        general: false,
        floating: false,
        appointment: false,
      });
    } else if (id === 6) {
      setShowOptions({
        design: false,
        icons: false,
        hours: false,
        gallery: false,
        reviews: false,
        general: bool,
        floating: false,
        appointment: false,
      });
    } else if (id === 7) {
      setShowOptions({
        design: false,
        icons: false,
        hours: false,
        gallery: false,
        reviews: false,
        general: false,
        floating: bool,
        appointment: false,
      });
    } else if (id === 8) {
      setShowOptions({
        design: false,
        icons: false,
        hours: false,
        gallery: false,
        reviews: false,
        general: false,
        floating: false,
        appointment: bool,
      });
    }
  };
  return (
    <>
      <div className="flex flex-col md:flex-row justify-between gap-5">
        <div className="flex flex-col gap-3 md:w-3/5 w-full">
          <div className="flex justify-between mb-5">
            <p className="flex text-lg md:text-2xl font-semibold items-center text-gray-500">
              {" "}
              {language.editCard}
            </p>
            <Button onClick={() => history.push(`/preview/${URL_title}`)}>
              Preview
            </Button>
          </div>
          <CardDesign toggle={showOptions.design} setToggle={handleToggle} />
          <MainIcons toggle={showOptions.icons} setToggle={handleToggle} />
          <OperatingHours toggle={showOptions.hours} setToggle={handleToggle} />
          <Gallery toggle={showOptions.gallery} setToggle={handleToggle} />
          <Appointment
            toggle={showOptions.appointment}
            setToggle={handleToggle}
          />
          <Reviews toggle={showOptions.reviews} setToggle={handleToggle} />
          <Floating toggle={showOptions.floating} setToggle={handleToggle} />
        </div>
        <div className="relative z-0 max-w-[380px] mx-auto">
          <img
            src={phone}
            alt="gallery"
            className="h-[700px] w-[355px] min-w-[355px]"
          />
          <div className="absolute inset-[18px] overflow-auto z-1 rounded-[35px] w-[317px] h-[665px]">
            <CardPreview />
          </div>
        </div>
      </div>
    </>
  );
};

export default CardBuilder;

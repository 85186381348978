import DesignCard from "../../../layout/designCard/designCard";
import { useState } from "react";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import { designCard } from "../../../store/slices/cardSlice";
import Api from "../../../api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import camera from "../../../assets/camera.webp";
import { editSettings } from "../../../store/slices/cardSlice";
import Button from "../../../common/Button";

const CardDesign = ({ toggle, setToggle }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.card);
  const language = useSelector((state) => state.language);
  const [previewUrl1, setPreviewUrl1] = useState(camera);
  const [previewUrl2, setPreviewUrl2] = useState(camera);
  const [previewUrl3, setPreviewUrl3] = useState(camera);
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const inputHandle = (e) => {
    const { name, value } = e.target;
    dispatch(designCard({ ...state, [name]: value }));
  };

  const inputFile = (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        dispatch(designCard({ ...state, [name]: event.target.result }));

        const formData = {
          id: state.id,
          image: event.target.result,
        };
        if (name === "logo") {
          setPreviewUrl1(event.target.result);
          await Api.updateLogo(formData);
        } else if (name === "cover_photo") {
          setPreviewUrl2(event.target.result);
          await Api.updateCoverPhoto(formData);
        } else if (name === "bg_image") {
          setPreviewUrl3(event.target.result);
          await Api.updateBgPhoto(formData);
        }
      };
      reader.readAsDataURL(files[0]);
    }
  };
  const submitData = async (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      await Api.updateTitle({ id: state.id, title: value.trim() });
    } else if (name === "slogan") {
      await Api.updateSlogan({ id: state.id, slogan: value.trim() });
    } else if (name === "text_color") {
      await Api.updateTextColor({ id: state.id, text_color: value });
    } else if (name === "bg_color") {
      await Api.updateBgColor({ id: state.id, bg_color: value });
    } else if (name === "cover_color") {
      await Api.updateCoverColor({ id: state.id, cover_color: value });
    }
  };
  const settingsHandle = async (key) => {
    dispatch(
      editSettings({
        ...state,
        settings: { ...state.settings, [key]: !state.settings[key] },
      })
    );

    await Api.updateSettings({
      ...state.settings,
      [key]: !state.settings[key],
    });
  };
  return (
    <>
      <DesignCard
        title={language.cardDesign}
        toggle={toggle}
        onShow={() => setToggle(1, !toggle)}
      >
        <div className="hidden md:grid md:grid-cols-1 2xl:grid-cols-3 gap-2 pt-5">
          <Picture
            label={language.businessLogo}
            image={previewUrl1}
            id="logo"
            name="logo"
            setImageHandle={inputFile}
            language={language.logo}
            value={state.settings.logo}
            settingsHandle={() => settingsHandle("logo")}
          />
          <Picture
            label={language.coverPhoto}
            image={previewUrl2}
            id="cover_photo"
            name="cover_photo"
            setImageHandle={inputFile}
            language={language.coverPhoto}
            value={state.settings.cover_photo}
            settingsHandle={() => settingsHandle("cover_photo")}
          />
          <Picture
            label={language.backgroundImage}
            image={previewUrl3}
            id="bg_image"
            name="bg_image"
            setImageHandle={inputFile}
            language={language.backgroundImage}
            value={state.settings.bg_image}
            settingsHandle={() => settingsHandle("bg_image")}
          />
        </div>
        <div className="hidden md:grid md:grid-cols-1 xl:grid-cols-2 gap-3 w-full">
          <Input
            type="text"
            label={language.businessName}
            name="title"
            value={state.title}
            inputHandle={inputHandle}
            submitData={submitData}
            language={language.title}
            isValue={state.settings.title}
            settingsHandle={() => settingsHandle("title")}
          />
          <Input
            type="text"
            label={language.slogan}
            name="slogan"
            value={state.slogan}
            inputHandle={inputHandle}
            submitData={submitData}
            language={language.slogan}
            isValue={state.settings.slogan}
            settingsHandle={() => settingsHandle("slogan")}
          />
        </div>
        <div className="hidden md:grid md:grid-cols-1 xl:grid-cols-2 gap-3 w-full">
          <ColorInput
            type="color"
            className="px-5"
            label={language.color}
            name="text_color"
            value={state.text_color}
            inputHandle={inputHandle}
            submitData={submitData}
          />
          <ColorInput
            type="color"
            label={language.backgroundColor}
            name="bg_color"
            value={state.bg_color}
            inputHandle={inputHandle}
            submitData={submitData}
          />
        </div>
        <div className="md:hidden w-auto">
          {currentStep === 1 && (
            <div className="grid grid-cols-1 2xl:grid-cols-3 gap-2 pt-5">
              <Picture
                label={language.businessLogo}
                image={previewUrl1}
                id="logo"
                name="logo"
                setImageHandle={inputFile}
                language={language.logo}
                value={state.settings.logo}
                settingsHandle={() => settingsHandle("logo")}
              />
              <Picture
                label={language.coverPhoto}
                image={previewUrl2}
                id="cover_photo"
                name="cover_photo"
                setImageHandle={inputFile}
                language={language.coverPhoto}
                value={state.settings.cover_photo}
                settingsHandle={() => settingsHandle("cover_photo")}
              />
              <Picture
                label={language.backgroundImage}
                image={previewUrl3}
                id="bg_image"
                name="bg_image"
                setImageHandle={inputFile}
                language={language.backgroundImage}
                value={state.settings.bg_image}
                settingsHandle={() => settingsHandle("bg_image")}
              />
            </div>
          )}
          {currentStep === 2 && (
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-3 w-full">
              <Input
                type="text"
                label={language.businessName}
                name="title"
                value={state.title}
                inputHandle={inputHandle}
                submitData={submitData}
                language={language.title}
                isValue={state.settings.title}
                settingsHandle={() => settingsHandle("title")}
              />
              <Input
                type="text"
                label={language.slogan}
                name="slogan"
                value={state.slogan}
                inputHandle={inputHandle}
                submitData={submitData}
                language={language.slogan}
                isValue={state.settings.slogan}
                settingsHandle={() => settingsHandle("slogan")}
              />
            </div>
          )}
          {currentStep === 3 && (
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-3 w-full">
              <ColorInput
                type="color"
                className="px-5"
                label={language.color}
                name="text_color"
                value={state.text_color}
                inputHandle={inputHandle}
                submitData={submitData}
              />
              <ColorInput
                type="color"
                label={language.backgroundColor}
                name="bg_color"
                value={state.bg_color}
                inputHandle={inputHandle}
                submitData={submitData}
              />
            </div>
          )}
          <div className="flex flex-col mt-5 gap-2">
            {currentStep < 3 && <Button onClick={nextStep}>Next</Button>}
            {currentStep > 1 && <Button onClick={prevStep}>Previous</Button>}
          </div>
        </div>
      </DesignCard>
    </>
  );
};

const Input = ({
  type,
  label,
  name,
  value,
  inputHandle,
  submitData,
  language,
  isValue,
  settingsHandle,
}) => {
  return (
    <div className="design_input_wrapper">
      <label className="flex justify-between gap-1 font-semibold pb-2">
        {label}
        <Option label={language} value={isValue} onChange={settingsHandle} />
      </label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={inputHandle}
        onBlur={submitData}
      />
    </div>
  );
};
const ColorInput = ({ type, label, name, value, inputHandle, submitData }) => {
  return (
    <div className="design_input_wrapper ">
      <label className="flex justify-between gap-1 font-semibold pb-2">
        {label}
      </label>
      <div className="flex px-3 py-1 border border-solid border-gray-300 rounded-lg text-center items-center justify-between">
        <div className="w-12 rounded-lg">
          <input
            type={type}
            name={name}
            value={value}
            onChange={inputHandle}
            onBlur={submitData}
            className="w-full rounded-lg border-none focus:ring-0"
          />
        </div>
        <label>{value}</label>
      </div>
    </div>
  );
};

const Picture = ({
  label,
  name,
  image,
  setImageHandle,
  id,
  language,
  value,
  settingsHandle,
}) => {
  return (
    <div className="flex flex-col gap-3">
      <label className="flex justify-between gap-1 font-semibold">
        {label}
        <Option label={language} value={value} onChange={settingsHandle} />
      </label>
      <div className="px-2 py-1 border border-solid border-gray-300 rounded-lg">
        <label
          htmlFor="logo-upload"
          className="flex cursor-pointer justify-between items-center"
        >
          <img src={image} alt={id} className="w-14 h-14 rounded-lg" />
          <label
            htmlFor={id}
            className="flex rounded-lg px-2 py-3 bg-gray-100 hover:bg-orange-100 text-gray-400 hover:text-orange-500 font-bold  items-center justify-center gap-1"
          >
            <input
              type="file"
              id={id}
              name={name}
              accept="image/*"
              onChange={setImageHandle}
              className="hidden"
            />
            <FontAwesomeIcon icon={faCloudArrowUp} size="lg" />
            Upload
          </label>
        </label>
      </div>
    </div>
  );
};
const Option = ({ label, value, onChange }) => {
  return (
    <div
      className="gen_setting"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {/* <p>{label}</p> */}
      <Switch
        checked={value}
        onChange={onChange}
        width={42}
        height={20}
        onColor="#2563EB"
        handleDiameter={16}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      />
    </div>
  );
};
export default CardDesign;

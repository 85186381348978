import { memo } from "react";
import { saveAs } from "file-saver";
import Carousel from "react-multi-carousel";

const IconsSlider = ({ icons, title }) => {
  const responsive = {
    mobile: {
      breakpoint: { max: 4000, min: 0 },
      items: 5,
      slidesToSlide: 5,
    },
  };
  const handleVCFDownload = (type, number) => {
    if (type !== "phn") return;
    const vCardContent =
      "BEGIN:VCARD\n" +
      "VERSION:3.0\n" +
      "FN:" +
      title +
      "\n" +
      "TYPE:Work\n" +
      "TEL:" +
      number +
      "\n" +
      "END:VCARD\n";
    const blob = new Blob([vCardContent], { type: "text/vcard" });
    saveAs(blob, "contact.vcf");
  };
  return (
    <div className="card_icons_slider">
      <Carousel
        responsive={responsive}
        arrows={false}
        showDots={true}
      >
        {icons.length > 0 &&
          icons.map(({ icon, type, value }, index) => (
            <a
              key={index}
              href={
                type === "sms" || type === "phn"
                  ? `tel:${value?.replaceAll(" ", "")}`
                  : type === "mail"
                  ? `mailto:${value?.replaceAll(" ", "")}`
                  : type === "wa"
                  ? `https://wa.me/${value?.replaceAll(" ", "")}`
                  : value?.replaceAll(" ", "")
              }
              target="_blank"
            >
              <img width={45} height={45} src={icon} alt="ICON" />
            </a>
          ))}
      </Carousel>
    </div>
  );
};

export default memo(IconsSlider);
